import mixhubbLogo from '../../_metronic/_assets/images/logo-dark.png';


const MixhubbLoader = () => {
    return (
        <div className="loaderBackendScreen">
            <img loading="lazy" src={mixhubbLogo} className="img-fluid" alt="Logo" title="Logo" />
            <svg className="splash-spinner" viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
        </div>
    )
}
export default MixhubbLoader;