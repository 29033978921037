import { useState, useEffect, useCallback, memo } from 'react';
import { Modal, Button } from "react-bootstrap";
import { showToast } from '../../utils';
import ImageCropper from './ImageCropper';
import { BsX } from "react-icons/bs";

export const CropperModal = (props) => {
    const { fileUrl, previewUrl, getCroppedImageData, onRemove, isLogo } = props;
    const [croppedImage, setCroppedImage] = useState();
    const [showModal, setShowModal] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);


    const getCropImageUrl = useCallback(() => {
        if (croppedImage) {
            let imageUrl = window.URL.createObjectURL(croppedImage);
            setIsBtnDisabled(true);
            getCroppedImageData(croppedImage, imageUrl, () => {
                setShowModal(false);
            });
        } else {
            showToast({ variant: 'error', message: "Please adjust image." });
        }
    }, [croppedImage]);

    const onHide = useCallback(() => {
        onRemove();
        setShowModal(false);
    }, [])

    const onChange = useCallback(() => {
        onHide();
        setTimeout(() => {
            document.getElementById('uploadImg')?.click()
        });
    }, [])

    useEffect(() => {
        if (previewUrl && !showModal) {
            setShowModal(true);
        }
    }, [previewUrl])


    return (
        <Modal className="ModalCommonInfo CripViewImages" show={showModal}>
            <Modal.Header closeButton>
                <Button className="ModalCloseBtn" onClick={onHide}><BsX /></Button>
            </Modal.Header>
            <Modal.Body>
                <ImageCropper
                    isLogo={isLogo}
                    imageToCrop={previewUrl}
                    onImageCropped={(croppedImage) => { setCroppedImage(croppedImage) }}
                />
                <img loading="lazy" src={fileUrl} alt='' title='' />
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isBtnDisabled} className='CropImg' onClick={getCropImageUrl}>Crop {isBtnDisabled && <span className="ml-3 spinner spinner-white"></span>} </Button>
                <Button disabled={isBtnDisabled} className='ChangeImg' onClick={onChange}>Change </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default memo(CropperModal);
