import {Suspense, lazy, useEffect} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import TawkTo from 'tawkto-react';
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import styled from 'styled-components'
import { svgComponents } from "../app/modules/Events/components/addEvent/svgComponents";

const EventDetail = lazy(() => import("../_metronic/_partials/widgets/advance-tables/eventDetail"));
const EventsPage = lazy(() => import("./pages/EventsPage"));
const AddEventPage = lazy(() => import("./pages/AddEventPage"));
const EventPage = lazy(() => import("./pages/EventPage"));
const AllEventsPage = lazy(() => import("./pages/AllEventsPage"));
const ExpoPage = lazy(() => import("./pages/ExpoPage"));
const UserProfilepage = lazy(() => import("./modules/UserProfile/UserProfilePage"));
const Teams = lazy(() => import("./pages/Teams"));
const AddTeam = lazy(() => import("../app/modules/Teams/AddTeam"));
const Logout = lazy(() => import("../app/modules/Auth/pages/Logout"));
// const SubscribePlan = lazy(() => import("../app/modules/Auth/pages/SubscribePlan"));
const UpgradeAddOns = lazy(() => import("../app/modules/Auth/pages/UpgradeAdd-OnsPlan"));
const Organizations = lazy(() => import("./pages/Organizations"));
const Notifications = lazy(() => import("./pages/Notifications"));
const PurchaseHistory = lazy(() => import("./pages/PurchaseHistory"));
const Init = lazy(() => import("./pages/Init"));

const StyledAnchor =styled.a`
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 125px;
    right: 15px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
`

const StyledSpan = styled.i`
    margin-top: -16px;
`


export default function BasePage(props) {
    useEffect(() => {
        /*if (window.location.pathname === "/dashboard" || window.location.pathname === "/init") {
          document.getElementsByTagName("META")[2].content = "noindex, nofollow";
        } else {
          document.getElementsByTagName("META")[2].content = "";
        }*/
        let script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=" + process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;
        document.getElementsByTagName("head")[0].appendChild(script);
    }, [props.location.pathname]);

    // useEffect(() => {
    //     (function loadHubSpot() {
    //         const d = document;
    //         const s = d.createElement("script");

    //         s.src = "https://js.hs-scripts.com/21664666.js";
    //         s.async = true;
    //         d.getElementsByTagName("body")[0].appendChild(s);
    //     })();
    // }, [])

    useEffect(() => {
        var tawk = new TawkTo(process.env.REACT_APP_TAWKTO_PROPERTY_ID, process.env.REACT_APP_TAWKTO_WIDGET_ID);
        tawk.onStatusChange((status) => {
            console.log(status)
        })
    }, []);

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <StyledAnchor className="whats-app" href="https://web.whatsapp.com/send?phone=919145838100&text=Hello%20Saurav%2C%20I%20need%20support" target="_blank">
                <StyledSpan className="my-float fa fa-whatsapp" >{svgComponents.WhatappSocial}</StyledSpan>
            </StyledAnchor>
            <Switch>
                {/* Redirect from root URL to /dashboard. */}
                <Redirect exact from="/" to="/dashboard"/>
                <ContentRoute path="/dashboard" component={EventsPage}/>
                <Route path="/user-profile" component={UserProfilepage}/>
                <ContentRoute exact path="/events/add" component={AddEventPage}/>
                <Route path="/events/:type" component={EventsPage}/>
                <Route path="/event/:id/:section/:action?/:subSectionId?/:subActionId?" component={EventPage}/>
                <Route exact path="/all-events" component={AllEventsPage}/>
                <Route exact path="/expo" component={ExpoPage}/>
                <Route exact path="/teams" component={Teams}/>
                <Route exact path="/teams/add" component={AddTeam}/>
                <Route exact path="/eventDetail" component={EventDetail}/>
                <Route path="/logout" component={Logout}/>
                {/* <Route path="/subscribe" component={SubscribePlan} /> */}
                <Route path="/upgrade" component={UpgradeAddOns}/>
                <Route path="/organizations" component={Organizations}/>
                <Route path="/notifications" component={Notifications}/>
                <Route path="/purchase-history" component={PurchaseHistory}/>
                <ContentRoute exact path="/init" component={Init}/>
                <Redirect to="/dashboard"/>
            </Switch>
        </Suspense>
    );
}