import { DropdownItem } from 'reactstrap';
import { svgComponents } from '../../app/modules/Events/components/addEvent/svgComponents';

const DropDownItem = ({
    wrapperCls,
    onClickHandler,
    onClickArgs,
    whichSvg,
    title,
    href
}) => {

    return (
        href ?
            <DropdownItem href={href}>
                {whichSvg ?
                    <div className="MenuSvg">
                        {svgComponents[whichSvg]}
                    </div> : null}{title}
            </DropdownItem>
            :
            <DropdownItem>
                <div className={wrapperCls} onClick={() => {
                    if (onClickHandler) {
                        onClickHandler(onClickArgs);
                    }
                }}>
                    {whichSvg ? <div className="MenuSvg"> {svgComponents[whichSvg]} </div> : null}
                    {title}
                </div>
            </DropdownItem>
    )
}
export default DropDownItem;