import { PagesWithSecondaryMenu } from './secondaryMenuPage';

export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const BASE_URL_FRONTEND = process.env.REACT_APP_MIXHUBB_FRONTEND_URL;

export const CHARGE_BEE_SITE_KEY = process.env.REACT_APP_SITE_KEY;

export const GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;

// Social Logins
export const LINKEDIN_CLIENT_ID = '77lcd0t3ha7eax';
export const GOOGLE_CLIENT_ID = '122087183572-e55v52brifhfejepk1igf1kbicr38mmq.apps.googleusercontent.com';
export const TWITTER_CONSUMER_KEY = 'VGMvEDgj57a03pORIkeYP6YF4';
export const TWITTER_CONSUMER_SECRET = 'e46R3rVTpVIFWXiuQjubqoHPxht5rggZEXIXLBKXkLVo6xYti5';
export const FACEBOOK_APP_ID = '988907491804504';

//common constants
export const PICKER_DATE_FORMAT = 'MM-dd-yyyy';
export const DATE_FORMAT = 'MM-DD-YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_12 = 'hh:mm A';
export const STRING_DATE = 'ddd D MMM';
export const STRING_DATE_SECOND = 'D MMM';
export const STRING_DATE_THIRD = 'MMM D'


//Events
export const EVENT_PRIMARY_COLOR = '#333333';
export const EVENT_SECONDARY_COLOR = '#2f73f7';
export const EVENT_NAVBAR_BACKGROUND_COLOR = '#333333';
export const EVENT_NAVBAR_PRIMARY_COLOR = '#2f73f7';
export const EVENT_NAVBAR_SECONDARY_COLOR = '#eb233c';

// file upload module types
export const FILE_UPLOAD_MODULE_TYPES = {
    BASIC: 'BASIC',
    LANDING_PAGE: 'LANDING_PAGE',
    LOBBY: 'LOBBY',
    STAGE: 'STAGE',
    ROOM: 'ROOM',
    NETWORKING: 'NETWORKING',
    EXPO: 'EXPO',
    SPEAKER: 'SPEAKER_IMPORT',
    ATTENDEE: 'ATTENDEE_IMPORT'
}

export const UPLOAD_FILE_TYPES = {
    IMAGE: 'IMAGE',
    AUDIO: 'AUDIO',
    VIDEO: 'VIDEO',
    DOCUMENT: 'DOCUMENT'
}

export const ELEMENT_TYPE = {
    INTEREST: 'INTEREST',
    SERVICE: 'SERVICE'
}
export const ACCEPTED_IMAGE_TYPES = 'image/jpeg, image/png, image/jpg';
export const ACCEPTED_VIDEO_TYPES = 'video/mp4, video/mov, video/avi';
export const ACCEPTED_DOCUMENT_TYPES = 'text/csv, application/vnd.ms-excel, .csv, .pdf, .txt, .doc';

export const PAGES_WITH_SECONDARY_MENU = PagesWithSecondaryMenu;

export const EVENT_TEMPLATE_TYPES = {
    ...FILE_UPLOAD_MODULE_TYPES
}

export const MEDIA_TYPES = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    DOCUMENT: 'DOCUMENT'
}

export const IMAGE_UPLOAD_MAX_SIZE = 4 // in MB
export const VIDEO_UPLOAD_MAX_SIZE = 10 // in MB
export const DOCUMENT_UPLOAD_MAX_SIZE = 1 //in MB

export const DROPZONE_IMAGE_UPLOAD_TITLE_TEXT = 'DROP YOUR IMAGE HERE'
export const DROPZONE_IMAGE_UPLOAD_SUPPORT_TEXT = 'SUPPORT: JPG, JPEG, PNG, (UPTO 5 MB)'

export const DROPZONE_COVER_SUGGESTION = 'For best result upload image of 16:9 ratio';

export const DROPZONE_VIDEO_UPLOAD_TITLE_TEXT = 'DROP YOUR VIDEO HERE'
export const DROPZONE_VIDEO_UPLOAD_SUPPORT_TEXT = 'SUPPORT: MP4, MOV, AVI (UPTO 50 MB)'

export const DROPZONE_DOCUMENT_UPLOAD_TITLE_TEXT = 'DROP YOUR FILE HERE'
export const DROPZONE_DOCUMENT_UPLOAD_SUPPORT_TEXT = 'SUPPORT: CSV (UPTO 20 MB)'

export const DOCUMENT_SUPPORT_TEXT = 'SUPPORT: CSV, PDF, TXT, DOC, DOCX, XLS';

// export const ROOM_SEATS_VALUES_LIST = [2, 4, 6, 8, 10];
export const ROOM_SEATS_VALUES_LIST = [
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "9+", value: 10 },

    ];
export const ROOM_NUMBER_VALUES_LIST = Array.from({ length: 10 }, (_, i) => i + 1);

export const BOOTH_CATEGORIES = {
    LARGE: 'Large',
    MEDIUM: 'Medium',
    SMALL: 'Small'
}
export const BOOTH_TYPES = {
    REGULAR: 'Regular',
    IMMERSIVE: 'Immersive',
    ['2D']: '2D'
}

export const EVENT_ENTRY_TYPE = {
    REGISTRATION: "REGISTRATION",
    DIRECT_CHECKIN: "DIRECT_CHECKIN",
    INVITE_ONLY: "INVITE_ONLY"
};

export const EVENT_TEMPLATE_NAME = {
    BASIC: "BASIC",
    ADVANCE: "ADVANCE",
    PRO: "PRO"
}

export const CHECK_IN_ENABLE_TIME = {
    1: "1 Hour Prior",
    2: "2 Hours Prior",
    3: "3 Hours Prior",
    4: "4 Hours Prior",
    5: "5 Hours Prior",
    6: "6 Hours Prior",
    12: "12 Hours Prior",
    18: "18 Hours Prior",
    24: "1 Day Prior"
};

export const WIDGET_COMPONENT_TYPES = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    CAROUSEL: 'CAROUSEL',
    GOTO: 'GOTO',
    HOTSPOT: 'HOTSPOT',
    TICKER: 'TICKER',
    FILE: 'FILE',
    IFRAME: 'IFRAME',
    CAROUSEL_IMAGE: 'CAROUSEL_IMAGE'
}

export const WIDGET_COMPONENT_TYPES_DETAILS = {
    IMAGE: { type: WIDGET_COMPONENT_TYPES.IMAGE, name: 'IMAGE', width: 200, height: 200, top: 10, left: 20, data: { src: "", type: 'paste_url', redirection_type: 'internal', enable_redirection: false } },
    VIDEO: { type: WIDGET_COMPONENT_TYPES.VIDEO, name: 'VIDEO', width: 300, height: 200, top: 20, left: 30, data: { src: "", type: 'paste_url' } },
    CAROUSEL: { type: WIDGET_COMPONENT_TYPES.CAROUSEL, name: 'CAROUSEL', width: 300, height: 300, top: 30, left: 40, data: { images: [], redirection_type: 'internal', enable_redirection: false } },
    GOTO: { type: WIDGET_COMPONENT_TYPES.GOTO, name: 'GO TO', width: 100, height: 30, top: 50, left: 40, data: { text: 'go to', enable_redirection: false, redirection_type: 'internal', type: 'text' } },
    HOTSPOT: { type: WIDGET_COMPONENT_TYPES.HOTSPOT, name: 'HOTSPOT', width: 50, height: 50, top: 50, left: 40, data: { enable_redirection: true, redirection_type: 'internal' } }, // attributes:type: text | image
    TICKER: { type: WIDGET_COMPONENT_TYPES.TICKER, name: 'TICKER', width: 300, height: 50, top: 50, left: 40, data: { text: 'please configure text in config', speed: 5 } },
    FILE: { type: WIDGET_COMPONENT_TYPES.FILE, name: 'FILE', width: 300, height: 50, top: 50, left: 40, data: { url: '', src: "", type: 'paste_url', } },
    IFRAME: { type: WIDGET_COMPONENT_TYPES.IFRAME, name: 'IFRAME', width: 300, height: 300, top: 50, left: 40, data: { url: '' } },
    CAROUSEL_IMAGE: { name: 'CAROUSEL IMAGE', url: "", data: { type: 'paste_url', src: "", enable_redirection: false, redirection_type: 'internal' } }
}

export const WIDGET_COMPONENTS_INPUT_TYPES = {
    IMAGE: { has_url: true, has_file_selection: true, has_redirection: true },
    VIDEO: { has_url: true },
    CAROUSEL: { image_has_redierction: true, select_images: true },
    GOTO: { has_text: true, has_redirection: true, select_image: true },
    HOTSPOT: { has_redirection: true }, // attributes:type: text | image
    TICKER: { has_text: true, has_time: true },
    FILE: { has_thumb: true, has_url: true, has_file_selection: true },
    IFRAME: { has_url: true }
}

export const DESIGN_LAYOUT = {
    LOBBY: 'Lobby',
    STAGE: 'Stage',
    BOOTH: 'Booth'
}

export const SPEAKER_ROLE = {
    SPEAKER: 'speaker',
    ORGANIZER: 'organizer'
}

export const getLanguage = () => {
    let languageList = [
        { value: "af", country: "Afrikaans" },
        { value: "sq", country: "Albanian" },
        { value: "am", country: "Amharic" },
        { value: "ar", country: "Arabic" },
        { value: "hy", country: "Armenian" },
        { value: "as", country: "Assamese" },
        { value: "ay", country: "Aymara" },
        { value: "az", country: "Azerbaijani" },
        { value: "bm", country: "Bambara" },
        { value: "eu", country: "Basque" },
        { value: "be", country: "Belarusian" },
        { value: "bn", country: "Bengali" },
        { value: "bho", country: "Bhojpuri" },
        { value: "bs", country: "Bosnian" },
        { value: "bg", country: "Bulgarian" },
        { value: "my", country: "Burmese" },
        { value: "ca", country: "Catalan" },
        { value: "ceb", country: "Cebuano" },
        { value: "ny", country: "Chichewa" },
        { value: "zh-CN", country: "Chinese (Simplified)" },
        { value: "zh-TW", country: "Chinese (Traditional)" },
        { value: "co", country: "Corsican" },
        { value: "hr", country: "Croatian" },
        { value: "cs", country: "Czech" },
        { value: "da", country: "Danish" },
        { value: "dv", country: "Dhivehi" },
        { value: "doi", country: "Dogri" },
        { value: "nl", country: "Dutch" },
        { value: "en", country: "English" },
        { value: "eo", country: "Esperanto" },
        { value: "et", country: "Estonian" },
        { value: "ee", country: "Ewe" },
        { value: "tl", country: "Filipino" },
        { value: "fi", country: "Finnish" },
        { value: "fr", country: "French" },
        { value: "fy", country: "Frisian" },
        { value: "gl", country: "Galician" },
        { value: "ka", country: "Georgian" },
        { value: "de", country: "German" },
        { value: "el", country: "Greek" },
        { value: "gn", country: "Guarani" },
        { value: "gu", country: "Gujarati" },
        { value: "ht", country: "Haitian Creole" },
        { value: "ha", country: "Hausa" },
        { value: "haw", country: "Hawaiian" },
        { value: "iw", country: "Hebrew" },
        { value: "hi", country: "Hindi" },
        { value: "hmn", country: "Hmong" },
        { value: "hu", country: "Hungarian" },
        { value: "is", country: "Icelandic" },
        { value: "ig", country: "Igbo" },
        { value: "ilo", country: "Ilocano" },
        { value: "id", country: "Indonesian" },
        { value: "ga", country: "Irish Gaelic" },
        { value: "it", country: "Italian" },
        { value: "ja", country: "Japanese" },
        { value: "jw", country: "Javanese" },
        { value: "kn", country: "Kannada" },
        { value: "kk", country: "Kazakh" },
        { value: "km", country: "Khmer" },
        { value: "rw", country: "Kinyarwanda" },
        { value: "gom", country: "Konkani" },
        { value: "ko", country: "Korean" },
        { value: "kri", country: "Krio" },
        { value: "ku", country: "Kurdish (Kurmanji)" },
        { value: "ckb", country: "Kurdish (Sorani)" },
        { value: "ky", country: "Kyrgyz" },
        { value: "lo", country: "Lao" },
        { value: "la", country: "Latin" },
        { value: "lv", country: "Latvian" },
        { value: "ln", country: "Lingala" },
        { value: "lt", country: "Lithuanian" },
        { value: "lg", country: "Luganda" },
        { value: "lb", country: "Luxembourgish" },
        { value: "mk", country: "Macedonian" },
        { value: "mai", country: "Maithili" },
        { value: "mg", country: "Malagasy" },
        { value: "ms", country: "Malay" },
        { value: "ml", country: "Malayalam" },
        { value: "mt", country: "Maltese" },
        { value: "mi", country: "Maori" },
        { value: "mr", country: "Marathi" },
        { value: "mni-Mtei", country: "Meiteilon (Manipuri)" },
        { value: "lus", country: "Mizo" },
        { value: "mn", country: "Mongolian" },
        { value: "ne", country: "Nepali" },
        { value: "no", country: "Norwegian" },
        { value: "or", country: "Odia (Oriya)" },
        { value: "om", country: "Oromo" },
        { value: "ps", country: "Pashto" },
        { value: "fa", country: "Persian" },
        { value: "pl", country: "Polish" },
        { value: "pt", country: "Portuguese" },
        { value: "pa", country: "Punjabi" },
        { value: "qu", country: "Quechua" },
        { value: "ro", country: "Romanian" },
        { value: "ru", country: "Russian" },
        { value: "sm", country: "Samoan" },
        { value: "sa", country: "Sanskrit" },
        { value: "gd", country: "Scots Gaelic" },
        { value: "nso", country: "Sepedi" },
        { value: "sr", country: "Serbian" },
        { value: "st", country: "Sesotho" },
        { value: "sn", country: "Shona" },
        { value: "sd", country: "Sindhi" },
        { value: "si", country: "Sinhala" },
        { value: "sk", country: "Slovak" },
        { value: "sl", country: "Slovenian" },
        { value: "so", country: "Somali" },
        { value: "es", country: "Spanish" },
        { value: "su", country: "Sundanese" },
        { value: "sw", country: "Swahili" },
        { value: "sv", country: "Swedish" },
        { value: "tg", country: "Tajik" },
        { value: "ta", country: "Tamil" },
        { value: "tt", country: "Tatar" },
        { value: "te", country: "Telugu" },
        { value: "th", country: "Thai" },
        { value: "ti", country: "Tigrinya" },
        { value: "ts", country: "Tsonga" },
        { value: "tr", country: "Turkish" },
        { value: "tk", country: "Turkmen" },
        { value: "ak", country: "Twi" },
        { value: "uk", country: "Ukrainian" },
        { value: "ur", country: "Urdu" },
        { value: "ug", country: "Uyghur" },
        { value: "uz", country: "Uzbek" },
        { value: "vi", country: "Vietnamese" },
        { value: "cy", country: "Welsh" },
        { value: "xh", country: "Xhosa" },
        { value: "yi", country: "Yiddish" },
        { value: "yo", country: "Yoruba" },
        { value: "zu", country: "Zulu" }
    ];
    return languageList;
};

// export const getLanguage  = () => {
//     let languageList = [
//     { value: "af", country: "Afrikaans" },
//     { value: "sq", country: "Albanian" },
//     { value: "am", country: "Amharic" },
//     { value: "ar", country: "Arabic" },
//     { value: "hy", country: "Armenian" },
//     { value: "as", country: "Assamese" },
//     { value: "ay", country: "Aymara" },
//     { value: "az", country: "Azerbaijani" },
//     { value: "bm", country: "Bambara" },
//     { value: "eu", country: "Basque" },
//     { value: "be", country: "Belarusian" },
//     { value: "bn", country: "Bengali" },
//     { value: "bho", country: "Bhojpuri" },
//     { value: "bs", country: "Bosnian" },
//     { value: "bg", country: "Bulgarian" },
//     { value: "my", country: "Burmese" },
//     { value: "ca", country: "Catalan" },
//     { value: "ceb", country: "Cebuano" },
//     { value: "ny", country: "Chichewa" },
//     { value: "zh-CN", country: "Chinese (Simplified)" },
//     { value: "zh-TW", country: "Chinese (Traditional)" },
//     { value: "co", country: "Corsican" },
//     { value: "hr", country: "Croatian" },
//     { value: "cs", country: "Czech" },
//     { value: "da", country: "Danish" },
//     { value: "dv", country: "Dhivehi" },
//     { value: "doi", country: "Dogri" },
//     { value: "nl", country: "Dutch" },
//     { value: "en", country: "English" },
//     { value: "eo", country: "Esperanto" },
//     { value: "et", country: "Estonian" },
//     { value: "ee", country: "Ewe" },
//     { value: "tl", country: "Filipino" },
//     { value: "fi", country: "Finnish" },
//     { value: "fr", country: "French" },
//     { value: "fy", country: "Frisian" },
//     { value: "gl", country: "Galician" },
//     { value: "ka", country: "Georgian" },
//     { value: "de", country: "German" },
//     { value: "el", country: "Greek" },
//     { value: "gn", country: "Guarani" },
//     { value: "gu", country: "Gujarati" },
//     { value: "ht", country: "Haitian Creole" },
//     { value: "ha", country: "Hausa" },
//     { value: "haw", country: "Hawaiian" },
//     { value: "iw", country: "Hebrew" },
//     { value: "hi", country: "Hindi" },
//     { value: "hmn", country: "Hmong" },
//     { value: "hu", country: "Hungarian" },
//     { value: "is", country: "Icelandic" },
//     { value: "ig", country: "Igbo" },
//     { value: "ilo", country: "Ilocano" },
//     { value: "id", country: "Indonesian" },
//     { value: "ga", country: "Irish Gaelic" },
//     { value: "it", country: "Italian" },
//     { value: "ja", country: "Japanese" },
//     { value: "jw", country: "Javanese" },
//     { value: "kn", country: "Kannada" },
//     { value: "kk", country: "Kazakh" },
//     { value: "km", country: "Khmer" },
//     { value: "rw", country: "Kinyarwanda" },
//     { value: "gom", country: "Konkani" },
//     { value: "ko", country: "Korean" },
//     { value: "kri", country: "Krio" },
//     { value: "ku", country: "Kurdish (Kurmanji)" },
//     { value: "ckb", country: "Kurdish (Sorani)" },
//     { value: "ky", country: "Kyrgyz" },
//     { value: "lo", country: "Lao" },
//     { value: "la", country: "Latin" },
//     { value: "lv", country: "Latvian" },
//     { value: "ln", country: "Lingala" },
//     { value: "lt", country: "Lithuanian" },
//     { value: "lg", country: "Luganda" },
//     { value: "lb", country: "Luxembourgish" },
//     { value: "mk", country: "Macedonian" },
//     { value: "mai", country: "Maithili" },
//     { value: "mg", country: "Malagasy" },
//     { value: "ms", country: "Malay" },
//     { value: "ml", country: "Malayalam" },
//     { value: "mt", country: "Maltese" },
//     { value: "mi", country: "Maori" },
//     { value: "mr", country: "Marathi" },
//     { value: "mni-Mtei", country: "Meiteilon (Manipuri)" },
//     { value: "lus", country: "Mizo" },
//     { value: "mn", country: "Mongolian" },
//     { value: "ne", country: "Nepali" },
//     { value: "no", country: "Norwegian" },
//     { value: "or", country: "Odia (Oriya)" },
//     { value: "om", country: "Oromo" },
//     { value: "ps", country: "Pashto" },
//     { value: "fa", country: "Persian" },
//     { value: "pl", country: "Polish" },
//     { value: "pt", country: "Portuguese" },
//     { value: "pa", country: "Punjabi" },
//     { value: "qu", country: "Quechua" },
//     { value: "ro", country: "Romanian" },
//     { value: "ru", country: "Russian" },
//     { value: "sm", country: "Samoan" },
//     { value: "sa", country: "Sanskrit" },
//     { value: "gd", country: "Scots Gaelic" },
//     { value: "nso", country: "Sepedi" },
//     { value: "sr", country: "Serbian" },
//     { value: "st", country: "Sesotho" },
//     { value: "sn", country: "Shona" },
//     { value: "sd", country: "Sindhi" },
//     { value: "si", country: "Sinhala" },
//     { value: "sk", country: "Slovak" },
//     { value: "sl", country: "Slovenian" },
//     { value: "so", country: "Somali" },
//     { value: "es", country: "Spanish" },
//     { value: "su", country: "Sundanese" },
//     { value: "sw", country: "Swahili" },
//     { value: "sv", country: "Swedish" },
//     { value: "tg", country: "Tajik" },
//     { value: "ta", country: "Tamil" },
//     { value: "tt", country: "Tatar" },
//     { value: "te", country: "Telugu" },
//     { value: "th", country: "Thai" },
//     { value: "ti", country: "Tigrinya" },
//     { value: "ts", country: "Tsonga" },
//     { value: "tr", country: "Turkish" },
//     { value: "tk", country: "Turkmen" },
//     { value: "ak", country: "Twi" },
//     { value: "uk", country: "Ukrainian" },
//     { value: "ur", country: "Urdu" },
//     { value: "ug", country: "Uyghur" },
//     { value: "uz", country: "Uzbek" },
//     { value: "vi", country: "Vietnamese" },
//     { value: "cy", country: "Welsh" },
//     { value: "xh", country: "Xhosa" },
//     { value: "yi", country: "Yiddish" },
//     { value: "yo", country: "Yoruba" },
//     { value: "zu", country: "Zulu" }];

//     return languageList;
// };
