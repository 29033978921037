import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import objectPath from "object-path";
import { connect, shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom'
import { Button } from '@material-ui/core';
import { useHtmlClassService } from "../../_core/MetronicLayout";
import userImg from '../../../../../src/_metronic/_assets/images/default_profile.png';
import ChangePassword from '../../../../app/modules/Events/ChangePassword';
import { actions, actionTypes } from '../../../../app/modules/Auth/_redux/authRedux';
import { UpdateProfile } from "../../../../app/modules/Events/UpdateProfile";
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import DropDownItem from "../../../../component/DropDownItem/DropDownItem";
import { svgComponents } from "../../../../app/modules/Events/components/addEvent/svgComponents";
import { getOrganization } from "../../../../app/modules/Events/_redux/crud";
import { showToast } from "../../../../app/utils";
import { getCountryCode } from "../../../../app/modules/Auth/_redux/authCrud";
import ConfirmationBox from "../../../../app/modules/common/ConfirmationBox";


function TopbarBase({
  askGeneralIntent,
  history
}) {

  const dispatch = useDispatch();
  const location = useLocation();

  const { auth: { isProfile, user, organizationDetails, openConfirmation } } = useSelector((state) => { return state }, shallowEqual);
  const [organizationName, setOrganizationName] = useState(localStorage.getItem('orgName') || user?.organization?.name);
  const [organizationId, setOrganizationId] = useState(localStorage.getItem('orgId') || user?.organization?.id);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const confirmationModal = {
    message: `Are you sure, you want to log out ?`,
    submitBtn: 'Yes',
    cancelBtn: 'No'
  };

  const getOrganizationDetails = async () => {
    const name = await localStorage.getItem("orgName");
    const id = await localStorage.getItem("orgId");
    setOrganizationName(name);
    setOrganizationId(id)
  }

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  const fetchCountryCode = () => {
    getCountryCode().then((res) => {
      if (res && res.data && res.data.status === "success") {
        let tempArr = [];
        Object.keys(res.data.data).map((e, i, a) => {
          tempArr.push({ code: Object.values(res.data.data)[i] })
        });
        dispatch({ type: actionTypes.CountryCode, payload: tempArr })
      }
    }).catch((err) => {

    });
  }

  const checkUser = () => {

    if (!user.has_organization && !organizationId && user.general_profile_complete === 0) {
      askGeneralIntent()
      history.push({ pathname: '/update-details', isSocialLogin: true, email: user.email, state: { state: 2, data: user } })
    }
    else if (!user.has_organization && !organizationId) {
      // if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      //   showToast({ message: "You are not allowed to create an event.", variant: 'error' });
      // } else {
      //   askGeneralIntent()
      //   history.replace('/update-company-details');
      // }
      askGeneralIntent()
      history.replace('/update-company-details');
    } else if (user.has_organization && !user.organization?.describe_your_organization &&
      !user.organization?.frequency_of_events && organizationId && organizationName === user.organization?.name) {
      // if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      //   showToast({ message: "You are not allowed to create an event.", variant: 'error' });
      // } else {
      //   askGeneralIntent()
      //   history.replace('/events-organisation-details');
      // }
      askGeneralIntent()
      history.replace('/events-organisation-details');
    }
    // else if (user.has_organization && user.organization?.describe_your_organization &&
    //   user.organization?.frequency_of_events && organizationId && organizationName === user.organization?.name &&
    //   !localStorage.getItem("isFreesubscriptionPurchased")) {
    //   history.replace('/subscribe');
    // }
    else {
      getOrganizations();
    }
  }

  const getOrganizations = () => {
    setIsBtnDisabled(true);
    getOrganization().then((res) => {
      if (res.data.status === "success") {
        setTimeout(() => {
          checkSelectedOrganizationExist(res.data.data);
        }, 1000);
      }
    }).catch((err) => {
      showToast({ message: err?.response?.data?.data || "some error occurred", variant: 'error' })
      setIsBtnDisabled(false);
    })
  }

  const checkSelectedOrganizationExist = (orgData) => {
    // if (organizationId && orgData.find(item => item.uid === organizationId)) {
    if (orgData.find(item => item.uid)?.uid) {
      setIsBtnDisabled(false);
      history.replace('/events/add', { state: { pageHeading: '' } });
    } else {
      localStorage.removeItem('orgName');
      localStorage.removeItem('orgId');
      history.push('/dashboard');
      setIsBtnDisabled(false);
    }
  }

  useEffect(() => {
    fetchCountryCode();
    getOrganizationDetails();
  }, [])


  const dropDownItemList = [
    {
      wrapperCls: "EditProfileModal", onClickHandler: dispatch, onClickArgs: { type: actionTypes.IS_PROFILE, payload: true },
      whichSvg: "EditProfile", title: "Edit Profile"
    },
    {
      wrapperCls: "EditProfileModal", onClickHandler: "", onClickArgs: "",
      whichSvg: "Team", title: "Team", href: "/teams",
    },
    {
      wrapperCls: "ChangePasswordModal", onClickHandler: setIsPassword, onClickArgs: true,
      whichSvg: "ChangePassword", title: "Change Password"
    },
    {
      wrapperCls: "EditProfileModal", onClickHandler: "", onClickArgs: "",
      whichSvg: "PuechesIcon", title: "Purchase History", href: "/purchase-history",
    },
    { title: "Log out", onClickHandler: dispatch, onClickArgs: { type: actionTypes.openConfirmation, payload: true } }
  ];

  return (
    <>
      <div className="orgName">
        <h3><Link to={'/organizations'}>{organizationDetails ? organizationDetails.name : organizationName ? organizationName : user?.organization?.name}</Link></h3>
      </div>
      <div className="topbar">
        {/* {layoutProps.viewSearchDisplay && <SearchDropdown />}

        {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}

        {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />}

        {layoutProps.viewCartDisplay && <MyCartDropdown />} 

        {layoutProps.viewQuickPanelDisplay && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
          >
            <div
              className="topbar-item"
              data-toggle="tooltip"
              title="Quick panel"
              data-placement="right"
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1"
                id="kt_quick_panel_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Layout/Layout-4-blocks.svg"
                    )}
                  />
                </span>
              </div>
            </div>
          </OverlayTrigger>
        )}

        {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}
        
  */}

        {!(location?.pathname?.includes("/events/add")) ? <div className="CreateAnEvent">
          <Button type="Button" disabled={isBtnDisabled} className="Btn AccountNevent mr-8" onClick={checkUser}> Create An Event{isBtnDisabled && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </div> : null}
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle>
            <div className="UserInfoDdl">
              <img loading="lazy" src={user?.profile_image_url || userImg} className="img-fluid userImg" alt="My Profile" title="My Profile" />
              {svgComponents["DownArrow"]}
            </div>
          </DropdownToggle>
          <DropdownMenu >
            <div className="ddlList">
              {dropDownItemList.map((elem, index) => {
                return (
                  <DropDownItem {...elem} key={"uniqueKey" + index.toString()} />
                )
              })}
            </div>
          </DropdownMenu>
        </Dropdown>
        {isPassword ? <ChangePassword hide={() => { setIsPassword(false) }} /> : null}
        {isProfile ? <UpdateProfile userProfile={user} /> : null}

        {openConfirmation ?
          <ConfirmationBox
            modalData={confirmationModal}
            onHide={() => { dispatch({ type: actionTypes.openConfirmation, payload: false }) }}
            onSubmit={() => {
              window.location.href = "/logout"
            }}
          /> : null
        }
        {/*layoutProps.viewUserDisplay && <QuickUserToggler />*/}
      </div>
    </>
  );
}

export const Topbar = connect(null, actions)(withRouter(TopbarBase));