/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { svgComponents } from "../../../../../app/modules/Events/components/addEvent/svgComponents";
import { useSelector } from "react-redux";
import { Fragment } from "react";

const MenuList = ({
  getMenuItemActive,
  linkTo,
  whichSvg,
  title,
  show = true
}) => {
  return (
    <li
      className={`menu-item ${getMenuItemActive(linkTo, false)}`}
      aria-haspopup="true"
    // style={{ display: show ? "block" : "none" }}
    >
      <NavLink className={`menu-link  ${!show ? 'RunningLoader' : ''}`} to={linkTo}>
        {show ?
          <Fragment>
            <div className="IconMenuSvg 1">
              {svgComponents[whichSvg]}
            </div>
            <span className="menu-text">{title}</span>
          </Fragment>
          : null}
      </NavLink>
    </li>
  )
}



const AsideMenuList = ({ layoutProps }) => {

  const location = useLocation();
  const { orgId, localOrgId } = useSelector(({ auth }) => {
    return ({
      orgId: auth?.user?.organization?.uid,
      localOrgId: localStorage.getItem('orgId')
    });
  });

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const menuList = orgId ? [
    { getMenuItemActive, linkTo: "/dashboard", whichSvg: "Globe", title: "All", },
    { getMenuItemActive, linkTo: "/teams", whichSvg: "Team", title: "Teams" },
    { getMenuItemActive, linkTo: "/organizations", whichSvg: "Organizer", title: "Organizations" },
    {
      getMenuItemActive, linkTo: "/upgrade", whichSvg: "EventPlan", title: "Event Plan",
      // show: orgId && orgId === localOrgId ? true : false 
      show: Boolean(orgId)
    },
    // { getMenuItemActive, linkTo: "/events/speaker", whichSvg: "Speaker", title: "Speaker" },
    // { getMenuItemActive, linkTo: "/events/organizer", whichSvg: "Organizer", title: "Organizer" }
  ] : [
    { getMenuItemActive, linkTo: "/dashboard", whichSvg: "Globe", title: "All", },
    { getMenuItemActive, linkTo: "/teams", whichSvg: "Team", title: "Teams" },
    { getMenuItemActive, linkTo: "/organizations", whichSvg: "Organizer", title: "Organizations" }
  ];

  return (
    <>
      {/* begin::Menu Nav */}
      {<ul className={`menu-nav MenuNavFirst  ${layoutProps.ulClasses}`}>
        {menuList.map((elem, index) => {
          return (
            <MenuList {...elem} key={"menulist" + index.toString()} />
          )
        })}
      </ul >
      }
      {/* end::Menu Nav */}
    </>
  );
}

export {
  AsideMenuList
}