export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(
    res => res,
    err => {
      if (err?.response?.data?.code === 'unauthorized') {
        window.location.href = '/logout';
      }
      else if (err?.response?.data?.code === 'unauthorized_event_access' || err?.response?.data?.code === 'event_not_found') {
        window.location.href = '/dashboard';
      }
      return Promise.reject(err);
    }
  );
}
