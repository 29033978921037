import React, { useCallback, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import DropZonePreview from './FileUploadDropZonePreview'
import { ACCEPTED_IMAGE_TYPES, API_BASE_URL, MEDIA_TYPES } from '../../../app/constants';
import { API_URLS } from '../../../app/modules/Events/_redux/apiUrls';
import UploadImageIcon from '../../../_metronic/_assets/images/UploadImageIcon.png';
import videoUploadIcon from '../../../_metronic/_assets/images/videoUploadIcon.png';
import DocumentUploadIcon from '../../_assets/images/document.png';
import store from '../../../redux/store';
import { uploadFile } from '../../../app/modules/Events/_redux/crud';
import { showToast } from '../../../app/utils';

const FileUploadDropZoneComponent = (props) => {
    const { defaultMediaUrl, defaultMediaType, disabled, onDefaultMediaUrlLoad, acceptedFileTypes, onDeleteDefaultMedia, removeFiles, onMediaUploaded, uploadTitleText, supportTitleText, onSelectedFileDelete, bgImgValidate, videoValidate, eventId, ratioSuggestion, isLogo, isCrop } = props;

    const defaultTitleText = 'DROP YOUR IMAGE HERE';
    const defaultSupportText = 'SUPPORT: JPG, JPEG, PNG, (UPTO 5 MB)';
    const [isImageCropper, setIsImageCropper] = useState(false);
    const [makeUrl, setMakeUrl] = useState("");


    const getCroppedImageData = useCallback((data, url, cb = () => { }) => {
        if (data) {
            const file = new File([data], 'image.jpeg');
            saveImageFile(file, cb);
            getUploadParams(file);
        }
    }, []);

    const saveImageFile = async (file, cb) => {
        const { eventModuleFor, fileType } = props;
        const body = new FormData()
        body.append('file', file)
        body.append('event_module_for', eventModuleFor)
        body.append('file_type', fileType);
        body.append('event_id', eventId || "");
        try {

            let imageData = await uploadFile(body);

            if (imageData.data.status === 'success') {
                if (cb) cb();
                setIsImageCropper(true);
                setMakeUrl(imageData?.data?.data?.file_url);
                if (onMediaUploaded && typeof onMediaUploaded === 'function') {
                    onMediaUploaded(imageData.data.data)
                }
            }
        } catch (err) {
            if (cb) cb();
            setIsImageCropper(false);
            setMakeUrl("");
        }
    }

    const getUploadParams = ({ file, meta }) => {
        const { eventModuleFor, fileType } = props

        const body = new FormData()
        body.append('file', file)
        body.append('event_module_for', eventModuleFor)
        body.append('file_type', fileType);
        body.append('event_id', eventId || "");

        const { auth: { authToken } } = store.getState();
        return { url: API_BASE_URL + API_URLS.UPLOAD_FILE_URL, body, headers: { Authorization: `Bearer ${authToken}` } }
    }

    const onFileChange = (data, status) => {
        if (status === 'done') {
            const response = JSON.parse(data.xhr.response);
            if (onMediaUploaded && typeof onMediaUploaded === 'function') {
                onMediaUploaded(response.data)
            }
        } else if (status === 'error_upload') {
            showToast({ variant: 'error', message: 'The file must be a file of type: jpeg, png, jpg, gif.' })
        }
    }

    const ValidationImage = (data) => {
        // let imgHight = data.meta.height
        // let imgWidths = data.meta.width
        let imgSize = data.meta.size
        let meta = data.meta
        var totalSizeMB = imgSize / Math.pow(1024, 2)

        // let width = imgWidths;
        // let height = imgHight;
        // let ratio = getWidthAndHeightRatio(width, height);
        // let aspectRatio = `${width / ratio}:${height / ratio}`;
        // let ratioWidth = width / ratio;
        // let ratioHeight = height / ratio;

        if (bgImgValidate === true) {
            if (totalSizeMB > 5) {
                meta.name = "";
                return 'Uploaded image size is over 5 MB.'
            }
            // if (ratioWidth !== 16 || ratioHeight !== 9) {
            //     meta.name = "";
            //     return 'The image aspect resolution must be 16:9.'
            // }
        }
        else if (videoValidate === true) {
            if (totalSizeMB > 50) {
                meta.name = "";
                return 'Uploaded video size is over 50 MB.'
            }
            else {
                return false
            }
        }
    }

    // function getWidthAndHeightRatio(a, b) {
    //     return (b === 0) ? a : getWidthAndHeightRatio(b, a % b);
    // }



    const onSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove())
    }

    const getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        });
    }

    const selectFileInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
        const onDefaultImageLoad = (e) => {
            if (onDefaultMediaUrlLoad) {
                onDefaultMediaUrlLoad({ width: e.target.naturalWidth, height: e.target.naturalHeight });
            }
        }

        const defaultImageDeleteButtonClicked = () => {
            if (onDeleteDefaultMedia)
                onDeleteDefaultMedia()
        }

        if (removeFiles && files.length) {
            onSubmit(files, files);
        }

        return (
            files.length === 0 ? <div className={`mixhubb-uploader-label-container UploaderFiles UploaFileStage ${defaultMediaUrl ? 'default-image-selected' : ''}`}>
                <label className="btn mixhubb-uploader-label mt-4 UploadLabel">
                    {!defaultMediaUrl && <>
                        <img loading="lazy" src={acceptedFileTypes ? acceptedFileTypes.includes('video') ? videoUploadIcon : acceptedFileTypes.includes('.doc') ? DocumentUploadIcon : UploadImageIcon : UploadImageIcon} className="img-fluid" alt="" title="" /> <br />
                        {uploadTitleText || defaultTitleText} <span>BROWSE</span>  <p>{supportTitleText || defaultSupportText}</p><p>{ratioSuggestion || ''}</p>
                        <div className="test">
                            <input id='uploadImg'
                                style={{ display: 'none' }}
                                type="file"
                                accept={accept}
                                disabled={disabled}
                                multiple={false}
                                onChange={e => {
                                    getFilesFromEvent(e).then(chosenFiles => {
                                        onFiles(chosenFiles)
                                    })
                                }}
                            />
                        </div>
                    </>}
                    {defaultMediaUrl && <>
                        {!disabled && <div className="uploadImgIcon" onClick={defaultImageDeleteButtonClicked}>
                            <div className="UploadDeleteIco">
                                <svg xmlns="http://www.w3.org/2000/svg" width="27.8" height="27.8" viewBox="0 0 27.8 27.8" fill="#f00"><g transform="translate(-693.1 -640.1)"><path d="M704.333,662.2a2.681,2.681,0,0,1-2.678-2.678V650.83a.557.557,0,0,1,.556-.556h10.152a.557.557,0,0,1,.556.556v8.688a2.681,2.681,0,0,1-2.678,2.678Zm4.291-8.573v5.223a.683.683,0,0,0,1.366,0v-5.223a.683.683,0,0,0-1.366,0Zm-4.041,0v5.223a.683.683,0,0,0,1.366,0v-5.223a.683.683,0,0,0-1.366,0Zm-3.148-3.989a.35.35,0,0,1-.35-.35v-1.078a1.684,1.684,0,0,1,1.683-1.682h2.423v-.211a.657.657,0,0,1,.656-.655h2.88a.657.657,0,0,1,.656.655v.211h2.423a1.684,1.684,0,0,1,1.682,1.682v1.078a.349.349,0,0,1-.349.35Z" /></g></svg>

                            </div>
                        </div>}
                        {(!defaultMediaType || defaultMediaType === MEDIA_TYPES.IMAGE) && <img loading="lazy" src={defaultMediaUrl} className="img-fluid" alt="" title="" onLoad={onDefaultImageLoad} />}
                        {(defaultMediaType === MEDIA_TYPES.VIDEO) && <video width="320" height="240" controls>
                            <source src={defaultMediaUrl} />
                            Your browser does not support the video tag.
                        </video>}
                        <a className="uploadPreviewIcon" target={'_blank'} rel="noreferrer" href={defaultMediaUrl} >
                            <div className="UploadPreviewIco">
                                <svg xmlns="http://www.w3.org/2000/svg" width="27.8" height="27.8" viewBox="0 0 27.8 27.8"><g transform="translate(-653.1 -790.1)"><g transform="translate(659 796)"><path d="M14,0H2A2,2,0,0,0,0,2V14a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2m.667,14a.667.667,0,0,1-.667.667H6V11a1,1,0,0,0-1-1H1.333V2A.667.667,0,0,1,2,1.333H14A.667.667,0,0,1,14.667,2Z" /><path d="M12.667,2.667h-4A.667.667,0,0,0,8.2,3.8L9.724,5.333,6.862,8.2a.667.667,0,1,0,.943.943l2.862-2.862L12.2,7.8a.667.667,0,0,0,.471.2.659.659,0,0,0,.255-.051.666.666,0,0,0,.411-.616v-4a.666.666,0,0,0-.667-.667" /></g></g></svg>
                            </div>
                        </a>
                    </>}
                </label>
            </div >
                : null
        )
    }

    return (
        <Dropzone
            onSubmit={onSubmit}
            onChangeStatus={onFileChange}
            InputComponent={selectFileInput}
            getUploadParams={getUploadParams}
            autoUpload={isCrop ? false : true}
            getFilesFromEvent={getFilesFromEvent}
            accept={acceptedFileTypes || ACCEPTED_IMAGE_TYPES}
            multiple={false}
            inputContent="Drop A File"
            styles={{
                dropzone: { width: '100%', minHeight: '200px', padding: 0, margin: 0, overflow: 'hidden' },
                dropzoneActive: { borderColor: 'green' },
                padding: 0,
                dropzoneReject: { borderWidth: '5px', borderColor: 'red' }
            }}
            classNames={{ dropzone: props.className }}
            SubmitButtonComponent={null}
            PreviewComponent={(props) => {
                return <DropZonePreview
                    {...props}
                    imageUrl={makeUrl}
                    setMakeUrl={setMakeUrl}
                    getCroppedImageData={(data, status) => { getCroppedImageData(data, status) }}
                    isImageCropper={isImageCropper}
                    setIsImageCropper={setIsImageCropper}
                    // onDelete={onSelectedFileDelete}
                    onDelete={isCrop ? onSelectedFileDelete : onDeleteDefaultMedia}
                    isLogo={isLogo}
                />
            }}
            disabled={disabled}
            validate={!defaultMediaType || defaultMediaType !== MEDIA_TYPES.DOCUMENT ? ValidationImage : ''}
        />
    );
};

export default FileUploadDropZoneComponent;