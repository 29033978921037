import { useState, useEffect, Fragment } from "react";
import { Button, Grid } from '@material-ui/core';
import { useFormik } from "formik";
import * as Yup from "yup";
import FileUploadDropZoneComponent from "../../../_metronic/_partials/controls/FileUploadDropZone";
import { ACCEPTED_IMAGE_TYPES, FILE_UPLOAD_MODULE_TYPES, UPLOAD_FILE_TYPES } from "../../constants";
import { updateProfile } from "../Auth/_redux/authCrud";
import { showToast } from "../../utils";
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { actions, actionTypes } from "../Auth/_redux/authRedux";
import InputForm from "../../../component/InputForm/InputForm";
import ModalWrapper from "../../../component/ModalWrapper/ModalWrapper";

const UpdateUserProfile = ({
	userProfile,
	saveUserData,
}) => {

	const dispatch = useDispatch();
	const { countryCode } = useSelector(({ auth }) => {
		return ({
			countryCode: auth?.countryCode
		});
	});

	const [initialValues] = useState({
		name: "", email: "", phone_number: "", company: "", designation: "", bio: "", dial_code: ""
	});
	const [dialCode, setDialCode] = useState([]);
	const [isBtnDisabled, setIsBtnDisabled] = useState(false);

	const hide = () => {
		dispatch({ type: actionTypes.IS_PROFILE, payload: false });
	}

	useEffect(() => {
		if (userProfile && countryCode) {
			formik.setValues({
				...userProfile
			});
			let tempData = [];

			countryCode.map(({ code }) => {
				tempData.push({ label: code, value: code });
			});
			setDialCode(tempData);
		}
	}, [userProfile, countryCode]);

	const UserProfileSchema = Yup.object().shape({
		name: Yup.string().nullable()
			.min(3, "Minimum 3 characters")
			.max(70, "Maximum 70 characters")
			.matches(/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/i, "Name should not contain special characters and space before and after not allowed!")
			.required("Name is required"),
		phone_number: Yup.string().nullable()
			.required("Phone number is required")
			.matches(/^\+?[0-9][0-9]{5,13}$/, "Phone number is invalid"),
		dial_code: Yup.string().nullable()
			.required("Dial code is required")
	});

	const onCustomBgUploaded = (data) => {
		formik.setFieldValue('profile_image_id', data.uid);
	}

	const onDeleteDefaultImage = () => {
		if (formik.values.profile_image_url === userProfile.profile_image_url) {
			formik.setFieldValue('profile_image_id', null)
			formik.setFieldValue('profile_image_url', null)
		} else {
			formik.setFieldValue('profile_image_id', userProfile.profile_image_id);
			formik.setFieldValue('profile_image_url', userProfile.profile_image_url);
		}
	}

	const updateUserProfile = () => {
		formik.handleSubmit();
	}

	const formik = useFormik({
		initialValues,
		validationSchema: UserProfileSchema,
		validateOnChange: true,
		onSubmit: (values) => {
			const valuesToSubmit = {
				name: values.name,
				email: values.email,
				phone_number: values.phone_number,
				company: values.company,
				designation: values.designation,
				bio: values.bio,
				profile_image_id: values.profile_image_id ? values.profile_image_id : '',
				dial_code: values.dial_code
			}
			setIsBtnDisabled(true);
			updateProfile(valuesToSubmit).then((res) => {
				if (res.data.status === 'success') {
					setIsBtnDisabled(false);
					saveUserData(res.data.data);
					showToast({ message: res.data.message, variant: 'success' });
					hide();
				}
			}).catch((error) => {
				setIsBtnDisabled(false);
				showToast({ message: error.response?.data?.message || "Some error occurred", variant: 'error' })
			})
		},
	});

	let updateInputArr = [
		{
			colCls: "col-md-6", label: "Full Name", inputCls: "px-6", placeholder: "", type: "text", name: "name",
			value: formik.values.name ? formik.values.name : "", onChange: formik.handleChange, row: "", rowsMin: "",
			inputProps: { maxLength: 70 }, errName: formik.errors.name, required: true,
		},
		{
			colCls: "col-md-6", label: "Email Id", inputCls: "px-6", placeholder: "", type: "text", name: "email",
			value: formik.values.email ? formik.values.email : "", row: "", rowsMin: "",
			inputProps: { maxLength: 30 }, disabled: true, errName: "", required: true,
		},
		{
			colCls: "col-md-6", label: "Dial Code", inputCls: "px-6", placeholder: "", type: "text", name: "dial_code",
			value: formik.values.dial_code ? formik.values.dial_code : "", onChange: formik.handleChange,
			row: "", rowsMin: "", inputProps: {}, errName: formik.errors.dial_code, select: true, options: dialCode
		},
		{
			colCls: "col-md-6", label: "Mobile Number", inputCls: "px-6", placeholder: "", type: "text", name: "phone_number",
			value: formik.values.phone_number ? formik.values.phone_number : "", onChange: formik.handleChange,
			row: "", rowsMin: "", inputProps: { maxLength: 14 }, errName: formik.errors.phone_number
		},
		{
			colCls: "col-md-6", label: "Company", inputCls: "px-6", placeholder: "", type: "text", name: "company",
			value: formik.values.company ? formik.values.company : "", onChange: formik.handleChange,
			row: "", rowsMin: "", inputProps: { maxLength: 100 }, errName: ""
		},
		{
			colCls: "col-md-6", label: "Designation", inputCls: "px-6", placeholder: "", type: "text", name: "designation",
			value: formik.values.designation ? formik.values.designation : "", onChange: formik.handleChange,
			row: "", rowsMin: "", inputProps: { maxLength: 100 }, errName: ""
		},
		// {
		// 	colCls: "col-md-12", label: "Bio", inputCls: "pb-1 pt-7 px-5", placeholder: "Type here...", type: "text",
		// 	name: "bio", value: formik.values.bio ? formik.values.bio : "", onChange: formik.handleChange,
		// 	row: 10, rowsMin: 10, inputProps: { maxLength: 200 }, errName: "", multiline: true
		// }
	];

	let footerContent = <Button disabled={isBtnDisabled} type="button" className="btn btn-primary ContBtn font-weight-bold px-9 py-4 my-3" onClick={updateUserProfile}>Update Profile {(isBtnDisabled) && <span className="ml-3 spinner spinner-white"></span>}</Button>


	return (
		<Fragment>
			<ModalWrapper
				title={"Edit Profile"}
				showModal={true}
				onHide={hide}
				footerContent={footerContent}
			>
				<form>
					<div className="row">
						<div className="col-lg-12">
							<div className="form-group">
								<Grid container spacing={10}>
									<Grid xs={12} md={8} lg={8} item className="EditFileModal">
										<div className="UploaFileStage">
											<FileUploadDropZoneComponent className="minHeightBox" eventId={""} defaultMediaUrl={formik.values.profile_image_url} eventModuleFor={FILE_UPLOAD_MODULE_TYPES.BASIC} fileType={UPLOAD_FILE_TYPES.IMAGE} onMediaUploaded={onCustomBgUploaded} acceptedFileTypes={ACCEPTED_IMAGE_TYPES} onDeleteDefaultMedia={onDeleteDefaultImage} bgImgValidate={true} isLogo={true} isCrop={true} />
										</div>
									</Grid>
								</Grid>
							</div>
						</div>
					</div>
					<div className="row" >
						{updateInputArr.map((elem, index) => {
							return (
								<InputForm
									key={elem?.label + index?.toString()}
									isErr={formik.errors}
									fromOtherComponent={true}
									{...elem}
								/>
							)
						})}
					</div>
				</form>
			</ModalWrapper>
		</Fragment>
	)
}
export const UpdateProfile = connect(null, actions)(withRouter(UpdateUserProfile));
