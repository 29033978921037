/* eslint-disable jsx-a11y/anchor-is-valid */
import { lazy, Suspense, useEffect } from "react";
import { Link, Switch, Redirect, Route } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../../../../_metronic/layout";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

const Registration = lazy(() => import("./Registration"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const VerifyEmail = lazy(() => import("./VerifyEmail"));
const UpdateRegistrationDetails = lazy(() => import("./UpdateRegistrationDetails"));
const RegistrationIntent = lazy(() => import("./RegistrationIntent"));
const RegistrationCompanyDetails = lazy(() => import("./RegistrationCompanyDetails"));
const RegistrationIntentEventDetails = lazy(() => import("./RegistrationIntentEventDetails"));
const AddOnboardingEvent = lazy(() => import('../../Events/AddOnboardingEvent'));
const VerifyYourself = lazy(() => import("./VerifyYourself"));
const ResetPassword = lazy(() => import("./ResetPassword"));


const AuthPage = (props) => {
  
  useEffect(() => {
    let script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=" + process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;
    document.getElementsByTagName("head")[0].appendChild(script);
  },[props.location.pathname]);

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
        <div className="flex-column-fluid flex-center mt-30 mt-lg-0">
          <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
              {/* <ContentRoute path="/login" component={Login} /> */}
              <Route path="/registration/:searchValue?" component={Registration} />
              <ContentRoute path="/verify-email" component={VerifyEmail} />
              <ContentRoute path="/verify-yourself" component={VerifyYourself} />
              <ContentRoute path="/update-details" component={UpdateRegistrationDetails} />
              <ContentRoute path="/forgot-password" component={ForgotPassword} />
              <ContentRoute path="/reset-password" component={ResetPassword} />
              <ContentRoute exact path="/update-company-details" component={RegistrationCompanyDetails} />
              <ContentRoute exact path="/general-intent" component={RegistrationIntent} />
              <ContentRoute exact path="/events-organisation-details" component={RegistrationIntentEventDetails} />
              <ContentRoute exact path="/add-first-event" component={AddOnboardingEvent} />
              <Redirect from="/" exact={true} to="/registration" />
              <Redirect to="/registration" />
            </Switch>
          </Suspense>
        </div>

        <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
          <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
            &copy; 2020 mixhub
          </div>
          <div className="d-flex order-1 order-sm-2 my-2">
            <Link to="/terms" className="text-dark-75 text-hover-primary">Privacy</Link>
            <Link to="/terms" className="text-dark-75 text-hover-primary ml-4">Legal</Link>
            <Link to="/terms" className="text-dark-75 text-hover-primary ml-4">Contact</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export { AuthPage };
