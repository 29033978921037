
import { Button } from '@material-ui/core';
import { Modal } from "react-bootstrap";
import { BsX } from "react-icons/bs";
import { GOOGLE_RECAPTCHA_KEY } from '../../constants';

export function ConfirmationBox(props) {
	const { modalData, onSubmit, onHide } = props;

	return (
		<form>
			<Modal className="CommonModalPopup" show={true} onHide={onHide}>
				<Modal.Header closeButton>
					<Button className="ModalCloseBtn" onClick={onHide}><BsX /></Button>
				</Modal.Header>
				<Modal.Body>
					<div className="ConfirmAction align-center mb-8 mt-2">
						<h4>{modalData.message}</h4>
					</div>
				</Modal.Body>
				<Modal.Footer>

					<div className="DeleteCancleRoom align-center mt-8">
						<Button type="button" className="btn WhiteBtn p-2 pl-10 pr-10 mr-5" onClick={onHide}>{modalData.cancelBtn}</Button>
						<Button type="button" data-sitekey={GOOGLE_RECAPTCHA_KEY} data-action='submit' className="btn CommonGtBtn p-2 pl-10 pr-10" onClick={onSubmit}>{modalData.submitBtn}</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</form>
	)

}
export default ConfirmationBox;