import axios from "axios";
import { API_BASE_URL } from "../../../constants";
import { API_URLS } from "./apiUrls";

export const getUrl = (url, eid, uid, bid) => {
  if (!eid && !uid && !bid) {
    return API_BASE_URL + url
  }
  if (!uid && !bid) {
    return API_BASE_URL + url.replace('E_ID', eid)
  } else if (!bid) {
    return API_BASE_URL + url.replace('E_ID', eid).replace('U_ID', uid)
  } else {
    return API_BASE_URL +  url.replace('E_ID', eid).replace('B_ID', bid).replace('U_ID', uid);
  }
}

/**
 * @method: getTimezones | method to get list of timezones
 * @returns : promise<List of timezones>
 */
export function getTimezones() {
  return axios.post(getUrl(API_URLS.TIMEZONE_LIST_URL));
}

/**
 * @method: create | method to create event
 * @param {*} values | {title:string, start_date: string(YYYY-MM-DD H:i:s), end_date: string(YYYY-MM-DD H:i:s), time_zone: string, description: string }
 * @returns : promise
 */
export function create(values) {
  return axios.post(getUrl(API_URLS.CREATE_EVENT_URL), values);
}

/**
 * @method: uploadFile | method to upload files
 * @param  event_module_for:string | 'BASIC' | 'LANDING_PAGE' | 'LOBBY' | 'STAGE' | 'ROOM' | 'NETWORKING' | * 'EXPO'
 * @param file_type: string | 'IMAGE' | 'AUDIO' | 'VIDEO' | 'DOCUMENT'
 * @param file : binary
 * @returns : promise
 */
export function uploadFile(formData) {
  return axios.post(getUrl(API_URLS.UPLOAD_FILE_URL), formData, { headers: { 'Content-Type': 'multipart/form-data;' } });
}

/**
 * @method: updateEvent | method to update event
 * @param {*} values | {title:string, start_date: string(YYYY-MM-DD H:i:s), end_date: string(YYYY-MM-DD H:i:s), time_zone: string, description: string } //many more
 * @returns : promise
 */
export function updateEvent(values) {
  // return axios.post(getEventUpdateUrl(values.eid), values);
  return axios.post(getUrl(API_URLS.UPDATE_EVENT_URL, values.eid), values);
}

/**
 * To get details of an event
 * @param {*} eventId | event Id
 * @returns event details
 */
export function getEventDetails(eventId) {
  // return axios.post(eventDetailsUrl(eventId));
  return axios.post(getUrl(API_URLS.GET_EVENT_DETAILS, eventId));
}

/**
 * @method: copyEvent | To create a duplicate event
 * @param {*} values | event details
 * @returns | duplicate event
 */
export function copyEvent(values) {
  return axios.post(getUrl(API_URLS.COPY_EVENT_URL), values);
}

/******** STAGE *********/

/**
 * @method: createStage | method to create event stage
 * @param {*} values | {title:string, description: string, eid: eventId }
 * @returns : promise
 */
export function createStage(values) {
  // return axios.post(getCreateStageUrl(values.eid), values);
  return axios.post(getUrl(API_URLS.CREATE_STAGE_URL, values.eid), values);
}

/**
 * @method: getStages | method to get list of created stages
 * @param {*} eventId | id of an event
 * @returns : List of stages
 */
export function getStages(eventId) {
  // return axios.get(getStagesList(eventId));
  return axios.get(getUrl(API_URLS.GET_STAGES_LIST_URL, eventId))
}

/**
 * @method: getStageDetails | method to get stage details
 * @param {*} eventId | id of an event
 * @param {*} stageId | id of a stage
 * @returns : List of stages
 */
export function getStageDetails(eventId, stageId) {
  return axios.get(getUrl(API_URLS.GET_STAGE_DATA_URL, eventId, stageId));
}

/**
 * @method: updateStage | method to edit the stage details
 * @param {*} eventId | Id of the event
 * @param {*} stageId | Id of a stage
 * @returns : Promise
 */
export function updateStage(values) {
  return axios.post(getUrl(API_URLS.UPDATE_STAGE_URL, values.eid, values.sid), values);
}

/**
 * @method: deleteStage | method to delete the stage
 * @param {*} values | {eid: event id, sid: stage id}
 * @returns | Promise
 */
export function deleteStage(values) {
  return axios.delete(getUrl(API_URLS.DELETE_STAGE_URL, values.eid, values.sid));
}

/**
 * @method: getDefaultSkins | method to get list of all default skins
 * @param: templateType : string | module where this media will be shown to user
 * @param: mediaType : string | media type (IMAGE | Video)
 * @returns : Promise
 */
export function getDefaultSkins(templateType, mediaTypes) {
  let mediaParam = mediaTypes.map(m => { return `&media[]=${m}` }).join('');
  return axios.get(`${getUrl(API_URLS.DEFAULT_SKINS_URL)}?templateType=${templateType}${mediaParam}`);
}

/**
 * Method to get the emojis.
 * @returns | List of emojis
 */
export function getEmojis() {
  return axios.get(getUrl(API_URLS.GET_EMOJI_ICON));
}

/*********** LOBBY *************/

/*
 * @method: getLobbyDetails | method to get stage details
 * @param {*} eventId | id of an event
 * @returns : Lobby details
 */
export function getLobbyDetails(eventId) {
  // return axios.get(getLobbyDataUrl(eventId));
  return axios.get(getUrl(API_URLS.GET_LOBBY_URL, eventId));
}

/**
 * @method: updateLobby | method to update the Lobby details
 * @param {*} eventId | Id of the event
 * @returns : Promise
 */
export function updateLobby(values) {
  // return axios.post(updateLobbyUrl(values.eid), values);
  return axios.post(getUrl(API_URLS.UPDATE_LOBBY_URL, values.eid), values);
}

/*************** ROOMS ***************/

/**
 * @method: reorderRooms | To update order of rooms
 * @param {*} eid | eventId : Id of an event
 * @returns | List of rooms
 */
export function reorderRooms(eid, roomsData) {
  // return axios.post(getRoomsReorderUrl(eid), roomsData);
  return axios.post(getUrl(API_URLS.ROOM_REORDER_URL, eid), roomsData);
}

/**
 * @method: createRoom | To create room
 * @param {*} eid | eventId : Id of an event
 * @param {*} data | {capacity: number, number_of_rooms: number}
 * @returns | Create new room
 */
export function createRoom(eid, data) {
  // return axios.post(getCreateRoomUrl(eid), data);
  return axios.post(getUrl(API_URLS.CREATE_ROOM, eid), data);
}

/**
 * @method: deleteRoom | To delete room
 * @param {*} eid | eventId : Id of an event
 * @param {*} uid | uid : uid of room
 * @returns | Delete a room
 */
export function deleteRoom(eid, uid) {
  // return axios.delete(getDeleteRoomUrl(eid, uid));
  return axios.delete(getUrl(API_URLS.DELETE_ROOM, eid, uid));
}

/**
 * @method: getRoomData | To fetch room details
 * @param {*} eid | eventId : Id of an event
 * @param {*} uid | uid : uid of room
 * @returns | fetch room details
 */
export function getRoomData(eid, uid) {
  return axios.get(getUrl(API_URLS.GET_ROOM_DETAILS, eid, uid));
}

/**
 * @method: updateRoom | To update room details
 * @param {*} eid | eventId : Id of an event
 * @param {*} uid | uid : uid of room
 * @returns | update room details
 */
export function updateRoom(eid, uid, data) {
  return axios.post(getUrl(API_URLS.UPDATE_ROOM_URL, eid, uid), data);
}

// Event tags
export function getEventTags(eid) {
  return axios.get(getUrl(API_URLS.GET_EVENT_TAGS_URL, eid))
}

export function getRoomsList(eid, type) {
  return axios.get(getUrl(API_URLS.GET_ROOMS_LIST, eid, type));
}

export function changeRecordingStatus(eid) {
  return axios.post(getUrl(API_URLS.TOGGLE_ROOMS_RECORDING, eid), {});
}

/**************** NETWORKING *************/

/**
 * @method: get networking detail
 * @param {*} eventId | Id of the event
 * @returns | networking detail
 */
export function getNetworkingDetail(eventId) {
  return axios.get(getUrl(API_URLS.GET_NETWORKING_DETAILS, eventId));
}

/**
 * @method: editNetworkingDetail | To update the networking details
 * @param {*} eventId | Id of the event
 * @returns | networking updated details
 */
export function editNetworkingDetail(values) {
  return axios.post(getUrl(API_URLS.UPDATE_NETWORKING, values.eid), values);
}

/**
 * @method: getNetworkingElementList | To get the list of networking element
 * @param {*} eventId | Id of an event
 * @returns | list of networking elements
 */
export function getNetworkingElementList(eventId) {
  return axios.get(getUrl(API_URLS.GET_NETWORKING_ELEMENTS, eventId));
}

/**
 * @method: createNetworkingElement | To create a networking element
 * @param {*} values | {eid: eventId, name: element name, type: element type }
 * @returns 
 */
export function createNetworkingElement(values) {
  return axios.post(getUrl(API_URLS.CREATE_NETWORKING_ELEMENT, values.eid), values);
}

/**
 * @method: deleteNetworkingElement | To delete networking element
 * @param {*} values | {eid: eventId, uid: networking element Id}
 * @returns | promise
 */
export function deleteNetworkingElement(values) {
  return axios.delete(getUrl(API_URLS.DELETE_NETWORKING_ELEMENT, values.eid, values.uid));
}

/**
 * @method: editNetworkingElement | To edit networking element
 * @param {*} values | networking element details
 * @returns | promise
 */
export function editNetworkingElement(values) {
  return axios.post(getUrl(API_URLS.EDIT_NETWORKING_ELEMENT, values.eid, values.uid), values);
}

/**
 * @method: activeNetworkingElement | To change status of networking element
 * @param {*} values | networking element details
 * @returns | promise
 */
export function activeNetworkingElement(values) {
  return axios.post(getUrl(API_URLS.UPDATE_NETWORKING_CHANGE_URL, values.eid, values.uid), values);
  // return axios.post(activeNetworkingElementUrl(values.eid, values.uid), values);
}

/************** TAGS **************/

/**
 * @method: createTag | To create new tags
 * @param {*} eid | eventId : Id of an event
 * @returns | promise
 */
export function createTag(eid, data) {
  return axios.post(getUrl(API_URLS.CREATE_EVENT_TAG_URL, eid), data);
}

/**
 * @method: deleteTag | To create new tags
 * @param {*} eid | eventId : Id of an event
 * @returns | promise
 */
export function deleteTag(eid, uid) {
  return axios.delete(getUrl(API_URLS.DELETE_EVENT_TAG_URL, eid, uid));
}

/****************** SPEAKERS *****************/

/**
 * @method: getSpeakers | To get list of speakers
 * @param {*} eid | event Id
 * @returns | List of speakers
 */
export function getSpeakers(eid, searchTerm) {
  return axios.get(`${getUrl(API_URLS.SPEAKERS_LIST_URL, eid)}?search=${searchTerm}`);
}

/**
 * @method: deleteSpeaker | To delete the selected speaker
 * @param {*} eid | event Id
 * @param {*} uid | speaker Id
 * @returns Promise
 */
export function deleteSpeaker(values) {
  return axios.delete(getUrl(API_URLS.SPEAKER_DELETE_URL, values.eid, values.uid));
}

/**
 * @method: checkIfEmailExist | To check if speaker is already exist
 * @param {*} values | {eventID, email}
 * @returns speaker details
 */
export function checkIfEmailExist(values) {
  return axios.post(getUrl(API_URLS.CHECK_IF_EMAIL_EXIST_URL, values.eid), values);
}

/**
 * @method: uploadSpeaker | To add speaker through csv upload
 * @param {*} values | event id, file
 * @returns | Promise
 */
export function uploadSpeaker(values) {
  return axios.post(getUrl(API_URLS.UPLOAD_SPEAKER_URL, values.eid), values);
}

/**
 * @method: createSpeaker | To create speaker
 * @param {*} values | Create speaker data
 * @returns | Promise
 */
export function createSpeaker(values) {
  return axios.post(getUrl(API_URLS.CREATE_SPEAKER_URL, values.eid), values);
}


/**
 * @method: checkIfTeamEmailExist | To check if team is already exist
 * @param {*} values | {email}
 * @returns email details
 */
export function checkIfTeamEmailExist(values) {
  return axios.post(getUrl(API_URLS.CHECK_IF_TEAM_EMAIL_EXIST_URL, values.email, values.organization_id), values);
}

/**
 * @method: createTeam | To create team
 * @param {*} values | Create team data
 * @returns | Promise
 */
export function createTeam(values) {
  return axios.post(getUrl(API_URLS.CREATE_TEAM_URL), values);
}

/**
 * @method: updateTeam | To update team
 * @param {*} values | team details
 * @returns | Promise
 */
export function updateTeam(values) {
  return axios.post(getUrl(API_URLS.UPDATE_TEAM_URL, values.organization_id, values.uid), values);
}


/**
 * @method: listTeam 
 * @param {*} values | team details
 * @returns | Promise
 */
export function listTeam(values) {
  return axios.post(getUrl(API_URLS.LIST_TEAM_URL), values);
}

/**
 * @method: deleteTeam 
 * @param {*} values | team details
 * @returns | Promise
 */
export function deleteTeam(values) {
  return axios.delete(getUrl(API_URLS.DELETE_TEAM_URL, values.oid, values.uid));
}

/**
 * @method: getSpeakerDetails | TO get speaker details
 * @param {*} values | eid, uid
 * @returns | Speaker details
 */
export function getSpeakerDetails(eid, uid) {
  return axios.get(getUrl(API_URLS.GET_SPEAKER_DETAILS_URL, eid, uid));
}

/**
 * @method: updateSpeaker | To update speaker
 * @param {*} values | speaker details
 * @returns | Promise
 */
export function updateSpeaker(values) {
  return axios.post(getUrl(API_URLS.UPDATE_SPEAKER_URL, values.eid, values.uid), values);
}

/**
 * @method: emailInvitationUrl | To send email invitation
 * @param {*} eid | event id
 * @param {*} uid | uid
 * @returns | Promise
 */
export function sendEmailInvitationToSpeaker(values) {
  return axios.post(`${getUrl(API_URLS.SPEAKER_EMAIL_INVITATION_URL, values.eid, values.uid)}`, values);
}

/**
 * @method: changeSpeakerRole | To change role of speaker
 * @param {*} values | speaker details
 * @returns | updated role
 */
export function changeSpeakerRole(values) {
  return axios.post(getUrl(API_URLS.CHANGE_SPEAKER_ROLE_URL, values.eid, values.uid), { changeto: values.role });
}

/****************** BOOTHS *********************/

/**
 * @method: getZonesList | To get list of zones
 * @param {*} eid | eventId : Id of an event
 * @returns | promise
 */
export function getZonesList(eid) {
  return axios.get(getUrl(API_URLS.GET_ZONES_LIST_URL, eid));
}

/**
 * @method: createBooth | To create a new booth
 * @param {*} eid | eventId : Id of an event
 * @returns | promise
 */
export function createBooth(eid, data) {
  return axios.post(getUrl(API_URLS.CREATE_BOOTHS_LIST_URL, eid), data);
}

/**
 * @method: updateBooth | To update a booth
 * @param {*} eid | eventId : Id of an event
 * @param {*} uid | boothId : UId of a booth
 * @returns | promise
 */
export function updateBooth(eid, data) {
  return axios.post(getUrl(API_URLS.UPDATE_BOOTH_URL, eid, data.uid), data);
}

export function addBoothOwners(eid, data) {
  return axios.post(getUrl(API_URLS.ADD_BOOTH_OWNERS_URL, eid, data.uid), data);
}

/**
 * @method: getZoneDetails | get zone details with booth list
 * @param: eid | eventId
 * @param: uid | zoneId
 * @returns: get zone details
 */
export function getZoneDetails(eid, uid) {
  return axios.get(`${getUrl(API_URLS.GET_ZONE_DETAILS_URL, eid, uid)}`);
}

/** @method: deleteZone | To delete a zone
 * @param {*} eid | eventId 
 * @returns {*} uid | zoneId
 */
export function deleteZone(eid, uid) {
  return axios.delete(getUrl(API_URLS.DELETE_ZONE_URL, eid, uid));
}

/** @method: deleteBooth | To delete a booth
 * @param {*} eid | eventId 
 * @returns {*} uid | boothId
 */
export function deleteBooth(eid, uid) {
  return axios.delete(getUrl(API_URLS.DELETE_BOOTH_URL, eid, uid));
}

/**
 * 
 * @param {*} eid | event id
 * @returns | list of all booth list
 */
export function getAllBoothList(eid, searchTerm) {
  return axios.get(`${getUrl(API_URLS.GET_ALL_BOOTH_LIST_URL, eid)}?search=${searchTerm}`);
}

/**
 * 
 * @param {*} eid | event id
 * @param {*} values | booth ids and zone id
 * @returns updated list
 */
export function updateEventBoothZone(values) {
  return axios.post(getUrl(API_URLS.UPDATE_EVENT_BOOTH_ZONE_URL, values.eid), values);
}
/*
 * @method: get booths list 
 * @param: eid
 * @returns: list of booths if no zones are present | if zones are present it returns list by zones
 */
export function getBoothList(eid) {
  return axios.get(getUrl(API_URLS.GET_BOOTHS_LIST_URL, eid));
}

/**
 * @method: getBoothDetails | To get the details of a booth
 * @param {*} eid | event Id
 * @param {*} uid | booth unique Id
 * @returns | booth details
 */
export function getBoothDetails(eid, uid) {
  return axios.get(getUrl(API_URLS.GET_EVENT_BOOTH_DETAIL_URL, eid, uid));
}

/**
 * @method: checkIfBoothOwnerExist | To check if booth owner exist
 * @param {*} values eid, uid, email
 * @returns | promise
 */
export function checkIfBoothOwnerExist(values) {
  return axios.post(getUrl(API_URLS.CHECK_IF_BOOTH_OWNER_EXISTS_URL, values.eid, values.uid), values);
}

/**
 * @method: createBoothOwner | To update booth owner
 * @param {*} eid | event Id
 * @param {*} uid | user Id
 * @param {*} values values
 * @returns | promise
 */
export function createBoothOwner(values) {
  return axios.post(getUrl(API_URLS.CREATE_BOOTH_OWNER_URL, values.eid, values.uid), values);
}

/**
 * @method: updateBoothOwner | To update booth owner
 * @param {*} eid | event Id
 * @param {*} uid | user Id
 * @param {*} values values
 * @returns | promise
 */
 export function updateBoothOwner(values) {
  return axios.post(getUrl(API_URLS.UPDATE_BOOTH_OWNER_URL, values.eid, values.uid, values.bid), values);
}

/**
 * @method: create zone
 * @param: eid
 * @returns: create zone
 */
export function createZone(eid, values) {
  return axios.post(getUrl(API_URLS.CREATE_ZONE_URL, eid), values);
}

/**
 * @method: updateZone | To update existing zone
 * @param {*} values | Update zone request body
 * @returns | updated zone
 */
export function updateZone(values) {
  return axios.post(getUrl(API_URLS.UPDATE_ZONE_URL, values.eid, values.uid), values);
}

/**
 * @method: getEventUsers
 * @param: eid
 * @param: search : search query to search users
 * @returns: create zone
 */
export function getEventUsersList(eid, searchKey, role) {
  let params;
  if (searchKey) {
    params = `search=${searchKey}`;
  }
  if (role) {
    params = params ? `${params}&role=${role}` : `role=${role}`
  }
  if (params)
    return axios.get(`${getUrl(API_URLS.GET_EVENT_USERS_LIST_URL, eid)}?${params}`);
  else
    return axios.get(`${getUrl(API_URLS.GET_EVENT_USERS_LIST_URL, eid)}`);
}

/******** LANDING **********/
/**
 * @method: getLandingPage | To get the landing page details
 * @param {*} eid | Event Id 
 * @returns | Landing page details
 */
export function getLandingPage(eid) {
  return axios.get(getUrl(API_URLS.GET_LANDING_PAGE_URL, eid));
}

/**
 * @method: updateEventLandingPage | To update event landing page 
 * @param {*} values | request body for update event landing page 
 * @returns | Promise 
 */
export function updateEventLandingPage(values) {
  return axios.post(getUrl(API_URLS.UPDATE_EVENT_LANDING_PAGE_DETAILS_URL, values.eid), values);
}

/** 
 * @method: getEventLandingPageInformation | To get the event landing page information
 * @param {*} eid | event Id
 * @returns | Event landing page details
 */
export function getEventLandingPageInformation(eid) {
  return axios.get(getUrl(API_URLS.GET_EVENT_LANDING_PAGE_INFO_URL, eid));
}

/**
 * @method: updateEventLandingPageInformation | To Update event landing page information
 * @param {*} eid | event Id
 * @returns | Promise
 */
export function updateEventLandingPageInformation(eid, values) {
  return axios.post(getUrl(API_URLS.UPDATE_EVENT_LANDING_PAGE_INFO_URL, eid), values);
}

/**
 * @method: getEventLandingPageContent | To get the event landing page content
 * @param {*} eid | event id
 * @returns | Event landing page content
 */
export function getEventLandingPageContent(eid) {
  return axios.get(getUrl(API_URLS.GET_EVENT_LANDING_PAGE_CONTENT, eid));
}

/**
 * @method: updateEventLandingPageContent | To Update event landing page content
 * @param {*} eid | event id
 * @param {*} values | request body
 * @returns | Promise
 */
export function updateEventLandingPageContent(eid, values) {
  return axios.post(getUrl(API_URLS.UPDATE_EVENT_LANDING_PAGE_CONTENT, eid), values);
}

/**
 * @method: getScheduleDays | method to get list of all event days
 * @param {*} eventId | id of an event
 * @returns : List of all days of and event
 */
export function getScheduleDays(eventId) {
  return axios.get(`${getUrl(API_URLS.GET_EVENT_SCHEDULE_DAYS_URL, eventId)}`);
}

/**
 * @method: save schedule 
 * @param {*} values | request body for add event schedule
 * @returns | Promise 
 */
export function addEventSchedule(values) {
  return axios.post(getUrl(API_URLS.CREATE_EVENT_SCHEDULE_URL, values.eid), values);
}

/**
 * @method: update schedule 
 * @param {*} values | request body for updating event schedule
 * @returns | Promise 
 */
export function updateEventSchedule(values) {
  return axios.post(getUrl(API_URLS.UPDATE_EVENT_SCHEDULE_URL, values.eid, values.uid), values);
}

/**
 * @method: getSchedulesList by date
 * @param: eid | event id
 * @param: date | date of which we need the schedule list
 */
export function getSchedulesList(eid, date) {
  return axios.get(`${getUrl(API_URLS.GET_SCHEDULES_LIST_BY_DATE, eid)}?date=${date}`);
}

/**
 * @method: delete event schedule
 * @param {*} eid event Id
 * @param {*} uid Schedule Id
 * @returns 
 */
export function deleteEventSchedule(eid, uid) {
  return axios.delete(`${getUrl(API_URLS.DELETE_EVENT_SCHEDULE_URL, eid, uid)}`);
}

/**
 * @method: hide event schedule
 * @param {*} eid event Id
 * @param {*} uid schedule Id
 * @returns Status
 */
export function hideEventSchedule(eid, uid) {
  return axios.get(`${getUrl(API_URLS.HIDE_EVENT_SCHEDULE_URL, eid, uid)}`);
}

/**
 * @method: getZonesStatusList | to get list of all zones with status 
 * @params: eId | event id
 */
export function getZonesStatusList(eId) {
  return axios.get(getUrl(API_URLS.GET_ZONES_STATUS, eId));
}

/**
 * @method: updateZoneStatus | to update zone status (enable/disable)
 * @param: eId | event id
 * @param: data | {schedule | lobby | stage | rooms | networking | expo : true | false}
 */
export function updateZoneStatus(eId, data) {
  return axios.post(getUrl(API_URLS.UPDATE_ZONE_STATUS, eId), data);
}

/**
 * @method: getWidgetsList | to get list of all widgets in a layout
 * @param: eId | event id
 * @param: layoutId : layout id
 */
export function getWidgetsList(eId, lId) {
  return axios.get(getUrl(API_URLS.GET_WIDGETS, eId, lId));
}

/**
 * @method: createWidget | create a widget
 * @param: eId | event id 
 * @param: layoutId : layout id
 * @param: data | widget data in json format
 */
export function createWidget(eId, lId, data) {
  return axios.post(getUrl(API_URLS.CREATE_WIDGET, eId, lId), data);
}

/**
 * @method: updateWidget | update a widget
 * @param: eId | event id 
 * @param: layoutId : layout id
 * @param: data | widget data in json format
 */
export function updateWidget(eId, lId, data) {
  return axios.post(getUrl(API_URLS.UPDATE_WIDGET, eId, lId), data);
}

/*
 * @method: deleteWidget | delete a widget
 * @param {*} eId | event id
 * @param {*} lId | layout id
 */
export function deleteWidget(eid, lid, uid) {
  return axios.delete(`${getUrl(API_URLS.DELETE_WIDGET, eid, lid)}?uid=${uid}`);
}

/**
 * @method: getZonesSubComponentsList
 */
export function getZonesSubComponentsList(eid) {
  return axios.get(getUrl(API_URLS.GET_ZONES_INTERNAL_SECTIONS_LIST, eid))
}

/******** ATTENDEE **********/

/**
 * @method: getAttendeesList | To get list of attendees
 * @param {*} eid | event id
 * @param {*} searchTerm | searched query/keyword
 * @returns | Attendees list
 */
export function getAttendeesList(eid, searchTerm) {
  return axios.get(`${getUrl(API_URLS.GET_ATTENDEES_LIST_URL, eid)}?search=${searchTerm}`);
}

/**
 * @method: uploadAttendeesByImportCsv | To import attendees list from a csv file
 * @param {*} values | event id and file id
 * @returns | response
 */
export function uploadAttendeesByImportCsv(values) {
  return axios.post(getUrl(API_URLS.UPLOAD_ATTENDEES_LIST_URL, values.eid), values);
}

/**
 * @method: addAttendeeByEmail | To import attendee by email
 * @param {*} values | event id and emails
 * @returns 
 */
export function addAttendeeByEmail(values) {
  return axios.post(getUrl(API_URLS.ADD_ATTENDEES_BY_EMAIL, values.eid), values);
}

/**
 * @method: sendEmailInvitationToAttendee | To send email invitation to attendee
 * @param {*} eid | event Id
 * @param {*} uid | Attendee Id
 * @returns | Promise
 */
export function sendEmailInvitationToAttendee(values) {
  return axios.post(`${getUrl(API_URLS.ATTENDEES_EMAIL_INVITATION_URL, values.eid, values.uid)}`, values);
}
/**
 * @method: getOrganization | To get the details of organization
 * @returns | result
 */
export function getOrganization() {
  return axios.get(getUrl(API_URLS.GET_ORGANIZATIONS));
}

/**
 * @method: getTotalSignUp | To get the total signup report
 * @param {*} eid event id 
 * @returns total signup report
 */
export function getTotalSignUp(eid, name, email, company, date) {
  return axios.get(`${getUrl(API_URLS.GET_TOTAL_SIGNUP_REPORT, eid)}?name=${name}&email=${email}&company=${company}&date=${date}`);
}

/**
 * @method: getTotalSignUpList | To get the total signup csv report
 * @param {*} eid | event id
 * @returns total signup csv report
 */
export function getTotalSignUpList(eid, name, email, company, date) {
  return axios.get(`${getUrl(API_URLS.GET_TOTAL_SIGNUP_REPORT_EXPORT, eid)}?name=${name}&email=${email}&company=${company}&date=${date}`);
}

/**
 * @method: getTotalLoggedIn | To get the total logged in report
 * @param {*} eid event id 
 * @returns total logged in report
 */
export function getTotalLoggedIn(eid, name, email, company, date) {
  return axios.get(`${getUrl(API_URLS.GET_TOTAL_LOGGED_IN, eid)}?name=${name}&email=${email}&company=${company}&date=${date}`);
}

/**
 * @method: getTotalLoggedInList | To get the total logged in csv report
 * @param {*} eid | event id
 * @returns | total logged in csv report
 */
export function getTotalLoggedInList(eid, name, email, company, date) {
  return axios.get(`${getUrl(API_URLS.GET_TOTAL_LOGGED_IN_EXPORT, eid)}?name=${name}&email=${email}&company=${company}&date=${date}`);
}

/**
 * @method: getTotalStreamingHours | To get the total streaming hours report
 * @param {*} eid event id 
 * @returns total streaming hours report
 */
export function getTotalStreamingHours(eid, searchTerm) {
  return axios.get(`${getUrl(API_URLS.GET_TOTAL_STREAMING_HOURS_REPORT, eid)}?search=${searchTerm}`);
}

/**
 * @method: getTotalLoggedInList | To get the total streaming hours csv report 
 * @param {*} eid | event id
 * @returns | csv list of streaming hours in 
 */
export function getTotalStreamingHoursList(eid, searchTerm) {
  return axios.get(`${getUrl(API_URLS.GET_TOTAL_STREAMING_HOURS_REPORT_EXPORT, eid)}?search=${searchTerm}`);
}

export function getTotalLiveLocation(eid, searchTerm, date) {
  return axios.get(`${getUrl(API_URLS.GET_TOTAL_LIVE_LOCATION_REPORT, eid)}?search=${searchTerm}&date=${date}`);
}

export function getTotalLiveLocationList(eid, searchTerm, date) {
  return axios.get(`${getUrl(API_URLS.GET_TOTAL_LIVE_LOCATION_REPORT_EXPORT, eid)}?search=${searchTerm}&date=${date}`);
}

export function getTotalRoomUser(eid, searchTerm) {
  return axios.get(`${getUrl(API_URLS.GET_TOTAL_ROOM_USER_REPORT, eid)}?search=${searchTerm}`);
}

export function exportRooms(eid) {
  return axios.get(`${getUrl(API_URLS.EXPORT_ROOMS, eid)}`);
}

export function getTotalRoomUserList(eid, searchTerm) {
  return axios.get(`${getUrl(API_URLS.GET_TOTAL_ROOM_USER_EXPORT, eid)}?search=${searchTerm}`);
}

export function getAllDashboardData(eid) {
  return axios.get(getUrl(API_URLS.GET_ALL_DASHBOARD_DATA_URL, eid));
}

export function getUsageRatesData(eid) {
  return axios.get(getUrl(API_URLS.GET_USAGES_RATES_DATA_URL, eid));
}

export function getLobbyData(eid) {
  return axios.get(getUrl(API_URLS.GET_LOBBY_DATA_REPORTS_URL, eid));
}

export function exportLobby(eid) {
  return axios.get(getUrl(API_URLS.EXPORT_LOBBY, eid));
}

export function getStagesData(eid) {
  return axios.get(getUrl(API_URLS.GET_STAGES_DATA_REPORTS_URL, eid));
}

export function getStagesSessionData(eid, uid) {
  return axios.get(`${getUrl(API_URLS.GET_STAGES_SESSION_DATA_URL, eid, uid)}`);
}

export function fetchSessionData(eid, uid) {
  return axios.get(`${getUrl(API_URLS.GET_EACH_SESSION_DATA_URL, eid, uid)}`);
}

export function getExpoData(eid) {
  return axios.get(getUrl(API_URLS.GET_EXPO_DATA_URL, eid));
}

export function getExpoDataList(eid, uid) {
  return axios.get(getUrl(API_URLS.GET_EXPO_DATA_LIST_URL, eid, uid));
}

export function fetchBoothData(eid, uid) {
  return axios.get(getUrl(API_URLS.BOOTH_EXPORT, eid, uid));
}

export function getFeedBackDataList(eid) {
  return axios.get(getUrl(API_URLS.GET_FEEDBACK_DATA_URL, eid));
}

export function getRegistrationGraphDetails(eid) {
  return axios.get(getUrl(API_URLS.GET_REGISTRATION_GRAPH_DATA_URL, eid));
}

export function getLoginGraphDetails(eid) {
  return axios.get(getUrl(API_URLS.GET_LOGIN_GRAPH_DATA_URL, eid));
}

export function exportFeedback(eid) {
  return axios.get(getUrl(API_URLS.EXPORT_FEEDBACK, eid));
}

export function getExpoSummaryReport(eid) {
  return axios.get(getUrl(API_URLS.GET_EXPO_REPORT_SUMMARY_URL, eid));
}

export function getExpoSummaryReportExport(eid) {
  return axios.get(getUrl(API_URLS.GET_EXPO_REPORT_SUMMARY_EXPORT_URL, eid));
}

export function getAllExpoReportExport(eid) {
  return axios.get(getUrl(API_URLS.GET_ALL_EXHIBITORS_REPORT_URL, eid));
}

export function getSpecificExpoReportExport(eid, uid) {
  return axios.get(getUrl(API_URLS.GET_SPECIFIC_EXHIBITORS_REPORT_URL, eid, uid));
}

/**
 * @method | getMeetingSchedulerSummaryReport | To get summary of meeting scheduler
 * @param {*} eid | eventId
 * @returns | Summary response
 */
export function getMeetingSchedulerSummaryReport(eid) {
  return axios.get(getUrl(API_URLS.GET_MEETING_SCHEDULE_REPORT_SUMMARY_URL, eid));
}

/**
 * @method | getMeetingSchedulerReportList | To get list of meeting scheduler
 * @param {*} eid | eventId
 * @returns | list details of meeting schedule
 */
export function getMeetingSchedulerReportList(eid, values) {
  return axios.post(getUrl(API_URLS.GET_MEETING_SCHEDULER_LIST_REPORT, eid), values);
}

/**
 * @method | getMeetingSchedulerReportExport | To export csv of meeting scheduler
 * @param {*} eid | eventId
 * @param {*} values |participant_name|meeting_status|meeting_dates|creation_dates
 * @returns 
 */
export function getMeetingSchedulerReportExport(eid) {
  return axios.get(getUrl(API_URLS.GET_MEETING_SCHEDULER_REPORT_EXPORT, eid));
}

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   NOTIFICATION API    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ //

export function getNotificationList(eid, searchTerm = "") {
  return axios.get(`${getUrl(API_URLS.GET_NOTIFICATION_LIST, eid)}?search=${searchTerm}`);
}

export function getNotificationTargetGroup(eid) {
  return axios.get(`${getUrl(API_URLS.GET_NOTIFICATION_TARGET_GROUP, eid)}`);
}

export function createNotification(eid, uid, data) {
  return axios.post(`${getUrl(API_URLS.CREATE_NOTIFICATION, eid)}`, data);
}

export function getNotificationListById(eid, uid) {
  return axios.get(`${getUrl(API_URLS.GET_NOTIFICATION_BY_ID, eid, uid)}`);
}

export function updateNotification(eid, uid, data) {
  return axios.post(`${getUrl(API_URLS.UPDATE_NOTIFICATION, eid, uid)}`, data);
}

export function deleteNotification(eid, uid) {
  return axios.delete(`${getUrl(API_URLS.DELETE_NOTIFICATION, eid, uid)}`);
}

export function onPaginationChange(pageUrl) {
  return axios.get(`${pageUrl}`);
}

export function pushNotification(eid, uid, data) {
  return axios.get(`${getUrl(API_URLS.PUSH_NOTIFICATION, eid, uid)}`, data);
}

export function clearUsersNotifications(eid, data) {
  return axios.get(`${getUrl(API_URLS.CLEAR_USER_NOTIFICATION, eid)}`, data);
}

/**
 * @method: getRecordingList | To export the total room user list
 * @param {*} eid | event Id
 * @returns recording list 
 */
export function getRecordingList(eid, searchTerm) {
  return axios.get(`${getUrl(API_URLS.GET_RECORDING_LIST, eid)}?search=${searchTerm}`);
}

/**
 * @method: getRecodingVideoURL | To get the url for recording video
 * @param {*} eid | event Id
 * @param {*} uid | recoding Id
 * @returns response
 */
export function getRecodingVideoURL(eid, uid) {
  return axios.get(`${getUrl(API_URLS.GET_RECORDING_VIDEO_URL, eid, uid)}`);
}

/**
 * @method: getScheduleList | To get schedule list in dropdown
 * @param {*} eid | eventId
 * @param {*} uid | stageId
 * @returns schedule details
 */
export function getScheduleList(eid, uid) {
  return axios.get(`${getUrl(API_URLS.GET_SCHEDULE_LIST_URL, eid, uid)}`);
}



//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   FEEDBACK API    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ //

export function getFeedBackList(eid, searchTerm = "") {
  return axios.get(`${getUrl(API_URLS.GET_FEEDBACK_LIST, eid)}?search=${searchTerm}`);
}

export function deleteFeedBack(eid, uid) {
  return axios.delete(`${getUrl(API_URLS.DELETE_FEEDBACK, eid, uid)}`);
}

export function getFeedBackListById(eid, uid) {
  return axios.get(`${getUrl(API_URLS.GET_FEEDBACK_BY_ID, eid, uid)}`);
}

export function createFeedBack(eid, uid, data) {
  return axios.post(`${getUrl(API_URLS.CREATE_FEEDBACK, eid)}`, data);
}

export function updateFeedBack(eid, uid, data) {
  return axios.post(`${getUrl(API_URLS.UPDATE_FEEDBACK, eid, uid)}`, data);
}

export function updateFeedBackStatus(eid, uid, data) {
  return axios.post(`${getUrl(API_URLS.UPDATE_FEEDBACK_STATUS, eid, uid)}`, data);
}


//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   QUESTION API    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ //

export function getQuestionList(eid, fid, searchTerm = "") {
  return axios.get(`${getUrl(API_URLS.GET_QUESTION_LIST, eid).replace("F_ID", fid)}?search=${searchTerm}`);
}

export function deleteQuestion(eid, uid, fid) {
  return axios.delete(`${getUrl(API_URLS.DELETE_QUESTION, eid, uid).replace("F_ID", fid)}`);
}

export function getQuestionListById(eid, fid, uid, data) {
  return axios.get(`${getUrl(API_URLS.GET_QUESTION_BY_ID, eid, uid).replace("F_ID", fid)}`, data);
}

export function createQuestion(eid, fid, uid, data) {
  return axios.post(`${getUrl(API_URLS.CREATE_QUESTION, eid).replace("F_ID", fid)}`, data);
}

export function updateQuestion(eid, fid, uid, data) {
  return axios.post(`${getUrl(API_URLS.UPDATE_QUESTION, eid, uid).replace("F_ID", fid)}`, data);
}

export function updateQuestionStatus(eid, uid, fid, data) {
  return axios.post(`${getUrl(API_URLS.UPDATE_QUESTION_STATUS, eid, uid).replace("F_ID", fid)}`, data);
}


/**
 * @method: getHelpDeskUserList | To get help desk user list
 * @param {*} eid
 * @param {*} searchTerm
 * @returns user list
 */
export function getHelpDeskUserList(eid) {
  return axios.get(getUrl(API_URLS.GET_HELP_DESK_USER_LIST, eid));
}

/**
 * @method: checkIfUserExist | To check if user is already exist
 * @param {*} values | {eventID, email}
 * @returns user details
 */
export function checkIfUserExist(values) {
  return axios.post(getUrl(API_URLS.GET_HELP_DESK_USER_CHECK, values.eid), values);
}

/**
 * @method: getUserDetails | TO get user details
 * @param {*} values | eid, uid
 * @returns | User details
 */
export function getUserDetails(eid, uid) {
  return axios.get(getUrl(API_URLS.GET_HELP_DESK_USER_DETAILS, eid, uid));
}

/**
 * @method: updateUser | To update user
 * @param {*} values | user details
 * @returns | Promise
 */
export function updateUser(values) {
  return axios.post(getUrl(API_URLS.GET_HELP_DESK_USER_UPDATE, values.eid, values.uid), values);
}

/**
 * @method: sendEmailInvitationToUser | To send email invitation
 * @param {*} eid | event id
 * @param {*} uid | uid
 * @returns | Promise
 */
export function sendEmailInvitationToUser(values) {
  return axios.post(`${getUrl(API_URLS.GET_HELP_DESK_USER_SENT_EMAIL_INVITE, values.eid, values.uid)}`, values);
}

/**
 * @method: deleteUser | To delete the selected user
 * @param {*} eid | event Id
 * @param {*} uid | user Id
 * @returns Promise
 */
export function deleteUser(values) {
  return axios.delete(getUrl(API_URLS.GET_HELP_DESK_USER_DELETE, values.eid, values.uid));
}

/**
 * @method: createHelpDeskUser | To create user
 * @param {*} values | Create user data
 * @returns | Promise
 */
export function createHelpDeskUser(values) {
  return axios.post(getUrl(API_URLS.GET_HELP_DESK_USER_CREATE, values.eid), values);
}


export function reorderQuestion(eid, fid, data) {
  return axios.post(getUrl(API_URLS.CHANGE_QUESTION_ORDER, eid).replace("F_ID", fid), data);
}




//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   EMAILER API    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ //

export function getEmailerList(eid, searchTerm = "") {
  return axios.get(`${getUrl(API_URLS.GET_EMAILER_LIST, eid)}?search=${searchTerm}`);
}

export function createEmailer(eid, uid, data) {
  return axios.post(`${getUrl(API_URLS.CREATE_EMAILER, eid)}`, data);
}

export function getEmailerListById(eid, uid) {
  return axios.get(`${getUrl(API_URLS.GET_EMAILER_BY_ID, eid, uid)}`);
}

export function getDynamicWords(eid) {
  return axios.get(`${getUrl(API_URLS.DYNAMIC_KEYWORDS, eid)}`);
}

export function updateEmailer(eid, uid, data) {
  return axios.post(`${getUrl(API_URLS.UPDATE_EMAILER, eid, uid)}`, data);
}

export function deleteEmailer(eid, uid) {
  return axios.delete(`${getUrl(API_URLS.DELETE_EMAILER, eid, uid)}`);
}

export function pushEmailTemplate(values) {
  return axios.post(`${getUrl(API_URLS.PUSH_EMAIL_TEMPLATE, values.eid, values.uid)}`, values);
}

export function sendIndividualMail(eid, uid, data) {
  return axios.post(`${getUrl(API_URLS.SEND_EMAIL, eid, uid)}`, data);
}



//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   SUBSCRIPTION API    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ //

export function getSubscriptionPlan(data) {
  return axios.post(`${getUrl(API_URLS.GET_SUBSCRIPTION_PLAN)}`,data);
}

export function sendHostedId(data) {
  return axios.post(`${getUrl(API_URLS.POST_HOSTED_ID)}`, data);
}

export function activePlan() {
  return axios.get(`${getUrl(API_URLS.GET_ACTIVE_PLAN)}`);
}

export function cancelSubscription(data) {
  return axios.post(`${getUrl(API_URLS.CANCEL_SUBSCRIPTION)}`,data);
}

export function purchaseHistory() {
  return axios.get(`${getUrl(API_URLS.PURCHASE_HISTORY)}`);
}

export function downloadInvoice(data) {
  return axios.post(`${getUrl(API_URLS.DOWNLOAD_INVOICE)}`,data);
}

/**
 * @method: updateComponentName | update component header name
 * @param {*} values | component headings
 * @returns | promise
 */
export function updateComponentName(eid, values) {
  return axios.post(`${getUrl(API_URLS.UPDATE_COMPONENT_HEADER_NAME_URL, eid)}`, values);
}

/**
 * @method: changeChatStatus | change the status of chat
 * @param {*} eid | Event Id
 * @param {*} status | Boolean value
 * @returns | Boolean 
 */
export function changeChatStatus(values) {
  return axios.post(`${getUrl(API_URLS.CHANGE_GROUP_CHAT_STATUS, values.eid)}`, values);
}

/**
 * @method: changeSocialShareStatus | toggle the status of social share option
 */
export function changeSocialShareStatus(values) {
  return axios.post(`${getUrl(API_URLS.CHANGE_SOCIAL_SHARE_LINK_STATUS, values.eid)}`, values);
}

/**
 * @method: changeSocialLoginStatus | toggle the status of social login option
 */
export function changeSocialLoginStatus(values) {
  return axios.post(`${getUrl(API_URLS.CHANGE_SOCIAL_LOGIN_STATUS, values.eid)}`, values);
}

/**
 * @method | clearGroupChat | clear group chat
 * @param {*} eid | eventId
 * @returns | Promise
 */
export function clearGroupChat(eid) {
  return axios.get(`${getUrl(API_URLS.CLEAR_GROUP_CHAT, eid)}`);
}

/**
 * @method | downloadGroupChat | download group chat
 * @param {*} eid | eventId
 * @returns | promise
 */
export function downloadGroupChat(eid) {
  return axios.get(`${getUrl(API_URLS.DOWNLOAD_CHAT_DATA, eid)}`);
}

/**
 * @method | getSecrets | to get the secrets
 * @returns promise
 */
export function getSecrets(eid) {
  return axios.get(`${getUrl(API_URLS.GET_SECRETS_URL, eid)}`);
}

/**
 * @method | generateKey | generate secret key
 * @param {*} eid | event id
 * @returns | generated key
 */
export function generateKey(eid) {
  return axios.post(`${getUrl(API_URLS.GENERATE_SECRET_KEY, eid)}`);
}

/**
 * @method | getListOfSource | get the list of sources
 * @param {*} eid | event id
 * @returns list of sources
 */
export function getListOfSource(eid) {
  return axios.get(`${getUrl(API_URLS.GET_SOURCE_LIST_URL, eid)}`);
}

/**
 * @method | createSource | create source
 * @param {*} values | values to submit
 * @returns promise
 */
export function createSource(values) {
  return axios.post(`${getUrl(API_URLS.CREATE_SOURCE_URL, values.eid)}`, values);
}

/**
 * @method | viewSource | view source
 * @param {*} values | eid, uid
 * @returns details of source
 */
export function viewSource(values) {
  return axios.get(`${getUrl(API_URLS.VIEW_SOURCE_DETAILS_URL, values.eid, values.uid)}`);
}

/**
 * @method | changeSourceStatus | to change the source status
 * @param {*} values | eid, uid
 * @returns changed status
 */
export function changeSourceStatus(values) {
  return axios.post(`${getUrl(API_URLS.CHANGE_SOURCE_STATUS_URL, values.eid, values.uid)}`,values);
}

/**
 * @method | deleteSource | delete the source
 * @param {*} values | eid, uid
 * @returns | promise
 */
export function deleteSource(values) {
  return axios.delete(`${getUrl(API_URLS.DELETE_SOURCE_URL, values.eid, values.uid)}`)
}

export function sendSelectedLanguage(values) {
  return axios.post(`${getUrl(API_URLS.SET_EVENT_LANGUAGE_URL, values.eid)}`,values);
}
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   Embed registration API    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ //

/**
 * @method | getEmbedWidget
 * @param {*} eid 
 * @returns 
 */
export function getEmbedWidget(eid) {
  return axios.get(`${getUrl(API_URLS.GET_EMBED_WIDGET_URL, eid)}`);
}

/**
 * @method | updateEmbedWidget
 * @param {*} eid 
 * @returns 
 */
export function updateEmbedWidget(values) {
  return axios.post(`${getUrl(API_URLS.UPDATE_EMBED_WIDGET_URL, values.eid)}`,values);
}

//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@   MEETING SCHEDULER API  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ //

/**
 * @method | toggleMeetingScheduler
 * @param {*} values meeting scheduler
 * @returns response
 */
export function toggleMeetingScheduler(eid) {
  return axios.post(`${getUrl(API_URLS.TOGGLE_MEETING_SCHEDULER_URL, eid)}`);
}

/**
 * @method | getMeetingSchedulerDetails
 * @param {*} values | eid
 * @returns | response
 */
export function getMeetingSchedulerDetails(eid) {
  return axios.get(`${getUrl(API_URLS.GET_MEETING_SCHEDULER_DETAILS, eid)}`);
}

/**
 * @method | updateMeetingScheduler
 * @param {*} values | schedule meeting data
 * @param {*} eid | event Id
 * @returns | status
 */
export function updateMeetingScheduler(values, eid) {
  return axios.post(`${getUrl(API_URLS.UPDATE_MEETING_SCHEDULER_URL, eid)}`, values);
}

/**
 * @method | masterOtpUpdate | To generate or update master otp
 * @param {*} values master otp params
 * @param {*} eid event Id
 * @returns | updated master otp
 */
export function masterOtpUpdate(values, eid) {
  return axios.post(`${getUrl(API_URLS.MASTER_OTP_UPDATE_URL, eid)}`, values);
}
// export function getEmailerListById(eid, uid) {
//   return axios.get(`${getUrl(API_URLS.GET_EMAILER_BY_ID, eid, uid)}`);
// }

// export function getDynamicWords(eid) {
//   return axios.get(`${getUrl(API_URLS.DYNAMIC_KEYWORDS, eid)}`);
// }

// export function updateEmailer(eid, uid, data) {
//   return axios.post(`${getUrl(API_URLS.UPDATE_EMAILER, eid, uid)}`, data);
// }

// export function deleteEmailer(eid, uid) {
//   return axios.delete(`${getUrl(API_URLS.DELETE_EMAILER, eid, uid)}`);
// }

// export function pushEmailTemplate(eid, uid) {
//   return axios.get(`${getUrl(API_URLS.PUSH_EMAIL_TEMPLATE, eid, uid)}`);
// }
